import './App.css';
import Navbar from './components/Navbar.js';
import BottomNavBar from './components/BottomNavBar.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/HomePage/Home';
import TopCats from './components/pages/HomePage/TopCats';
import MyAccount from './components/pages/MyAccount/MyAccount';
import ScheduledRentalDisplay from './components/pages/MyAccount/ScheduledRentalDisplay';
import MyPickup from './components/pages/MyAccount/MyPickup';
import Contacts from './components/pages/messages/Contacts';
import Message from './components/pages/messages/message';
import ReportIssue from './components/pages/MyAccount/ReportIssue';
import SchduleActionPage from './components/pages/MyAccount/ScheduleActionPage';
import MyOwnerRentals from './components/pages/MyAccount/MyOwnerRentals';
import PersonalInfo from './components/pages/MyAccount/PersonalInfo';
import MyFavorites from './components/pages/MyAccount/MyFavorites';
import MyListings from './components/pages/MyAccount/MyListings';
import MyPayments from './components/pages/MyAccount/MyPayments';
import TaxPage from './components/pages/MyAccount/TaxPage';

import Help from './components/pages/HelpPage/Help';
import FAQ from './components/pages/HelpPage/FAQ';
import AddRentalSentToApp from './components/pages/AddRental/AddRentalSentToApp';
import React,{useEffect} from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import SearchDetail from './components/pages/Search/SearchDetail';
import CompleteSchedule from './components/pages/Search/CompleteSchedule';
import ScheduleThankYou from './components/pages/Search/ScheduleThankYou';
import ScheduleRental from './components/pages/Search/ScheduleRental';
import DatePicker from './components/pages/Search/DatePicker';
import UserDetails from './components/pages/Search/UserDetail';
import RentalDisplay from './components/pages/Search/RentalDisplay';
import Login from './Login';
import NewUserAdd from './NewUserAdd';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import UserProvider from './providers/UserProvider'
import UserReview from './components/pages/Reviews/UserReview'
import HelpDetails from './components/pages/HelpPage/HelpDetails';
import Terms from './components/pages/HelpPage/Terms';
import ContactUs from './components/pages/HelpPage/ContactUs';
import Notifications from './components/pages/MyAccount/Notifications';
import TranDetails from './components/pages/MyAccount/TranDetails';
import Privacy from './components/pages/HelpPage/Privacy';
import AppConnect from './AppConnect';
import Footer from './Footer';
import BlogTop from './components/pages/Blog/blogTop';
import Blog1 from './components/pages/Blog/Blogs/blogTop1';
import VideoPage from './components/pages/HelpPage/VideoPage';

function App() {
	
	  return (
	  <UserProvider>
		<Router>
			<CssBaseline />
			<Container style={{minHeight:'600px'}}>
			<Navbar />
				<Switch>
					<Route path='/' exact component={Home}/>
					<Route path='/myaccount'component={MyAccount} />
					<Route path='/addnewrental' exact component={AddRentalSentToApp}/>
					<Route path='/help' exact component={Help}/>
					<Route path='/search' exact component={SearchDetail}/>
					<Route path='/category/:id' exact component={SearchDetail}/>
					<Route path='/renterrentals' exact component={MyPickup}/>
					<Route path='/ownerrentals' exact component={MyOwnerRentals}/>
					<Route path='/myfavorites' exact component={MyFavorites}/>
					<Route path='/mylistings' exact component={MyListings}/>
					<Route path='/mypayments' exact component={MyPayments}/>
					<Route path='/login' exact component={Login}/>
					<Route path='/signup' exact component={SignUp}/>
					<Route path='/forgotpassword' exact component={ForgotPassword}/>
					<Route path='/personalinfo' exact component={PersonalInfo}/>
					<Route path='/rental/:rentalid' exact component={RentalDisplay}/>
					<Route path='/datepicker' exact component={DatePicker}/>
					<Route path='/userdetails' exact component={UserDetails}/>
					<Route path='/schedule' exact component={ScheduleRental}/>
					<Route path='/completeschedule' exact component={CompleteSchedule}/>
					<Route path='/schedulethankyou' exact component={ScheduleThankYou}/>
					<Route path='/scheduledisplay' exact component={ScheduledRentalDisplay}/>
					<Route path='/helpdetails' exact component={HelpDetails}/>
					<Route path='/scheduleaction' exact component={SchduleActionPage}/>
					<Route path='/reportissue' exact component={ReportIssue}/>
					<Route path='/newuseradd' exact component={NewUserAdd}/>
					<Route path='/contacts' exact component={Contacts}/>
					<Route path='/message' exact component={Message}/>
					<Route path='/faq' exact component={FAQ}/>
					<Route path='/userreview' exact component={UserReview}/>
					<Route path='/topcategories' exact component={TopCats}/>
					<Route path='/contactus' exact component={ContactUs}/>
					<Route path='/notifications' exact component={Notifications} />
					<Route path='/transactiondetails' exact component={TranDetails} />
					<Route path='/taxinfo' exact component={TaxPage}/>
					<Route path='/terms' exact component={Terms}/>
					<Route path='/privacy' exact component={Privacy}/>
					<Route path='/apps' exact component={AppConnect}/>
					<Route path="/blog" exact component={BlogTop}/>
					<Route path="/blog/:id" exact component={Blog1}/>
					<Route path="/videos" exact component={VideoPage}/>
				</Switch>
				<BottomNavBar/>
			</Container>
			<Footer/>
		</Router>
		</UserProvider>
	  );
}

export default App;
