import { Typography } from '@material-ui/core'
import React , {useEffect,useState } from 'react'
import { useHistory } from 'react-router';
import { Button, Container, Divider } from '@material-ui/core'
import './Terms.css';

export default function Privacy(){
    const history = useHistory()
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <div className="marginBox">
<p className="centerText"><strong>RentEase Privacy Policy</strong></p>
<ol>
<li><strong>Recitals</strong>.&nbsp;</li>
<ol>
<li>RentEase is engaged in the business of facilitating personal property leases between its Users through desktop applications, mobile applications, websites, platforms, content, and integrated services (the &ldquo;<strong><em>Service</em></strong>&rdquo;) in exchange for Service Fees.</li>
<li><strong>RentEase LLC</strong>, is a North Dakota limited liability company, PO Box PO Box 227, Oakes, ND 58474.</li>
<li>This Privacy Policy describes how RentEase, LLC and its affiliates (&ldquo;<strong><em>RentEase</em></strong>&rdquo;, &ldquo;<strong><em>us</em></strong>&rdquo;, or &ldquo;<strong><em>we</em></strong>&rdquo;), process personal information in relation to your use of the RentEase User Agreement. (&ldquo;<strong><em>User Agreement</em></strong>&rdquo;)</li>
<li>All defined terms in this Privacy Policy have the same definition as in the User Agreement.</li>
</ol>
<li><strong>Personal Information We Collect</strong>. We collect personal information about you when you use the RentEase Service. Without it, we may not be able to provide all services requested. If you choose not to provide this information, your use of the RentEase Service may be limited. This information may include:</li>
<ol>
<li>Your contact information, including phone number, address, and email address.</li>
<li>Your information pertaining to Listed rental items.</li>
<li>photo identification.</li>
</ol>
<li><strong>Payment Information We Collect</strong>. We collect certain payment information about you when you use the RentEase Service. Without it, we cannot help facilitate secure payments for services requested. If you choose not to provide this information, your use of the RentEase Service may be limited. This information may include:</li>
<ol>
<li>billing address (if different from your personal address).</li>
<li>last four digits of debit/credit card information.</li>
<ol>
<li>In providing the last four digits of your card information, RentEase will apply a Payment Card Industry Security Standard (PCIDSS) tokenized credit card to secure storage of your information.&nbsp;</li>
</ol>
<li>information necessary to connect your Stripe account to your RentEase account.</li>
<li>payment transaction information such as payment instrument used, date and time, payment amount, and other related transaction details.</li>
</ol>
<li><strong>Location Information We Collect</strong>. We automatically collect information such as precise or approximate location determined from your IP address or mobile device&rsquo;s GPS depending on your device settings. We may also collect this information when you&rsquo;re not using the app if you enable this through your settings or device permissions.</li>
<li><strong>Device Information We Collect</strong>. When you use the RentEase Service, we automatically collect certain information related to your device. This information may include:</li>
<ol>
<li>usage information, including application usage, such as the pages or content you view, searches for listings, bookings you have made, time spent using the Service, and other actions related to the RentEase Service.</li>
<li>log data and device information such as details about how you&rsquo;ve used the RentEase Service (including if you clicked on links to any third-party websites or applications), device type, IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, image folder access, notification settings, and the pages you&rsquo;ve viewed or engaged with before or after using the RentEase Service. We may collect this information even if you haven&rsquo;t logged in or created a Rentease account.</li>
</ol>
<li><strong>Information We Collect From Third-Parties</strong>. We collect personal information about you from other sources, such as:</li>
<ol>
<li>if you connect or login to the RentEase Service with a third party service (e.g. Google, Apple, Facebook, Twitter, Instagram), you direct the service to send us information such as your account information, friends list, followers, and other information as controlled by that service or as authorized by you via your privacy settings at that service.</li>
<li>to the extent permitted by applicable laws, we may obtain reports of criminal records, sex offender registrations, and other information about you and/or your background. We may use your information, including your full name and date of birth, to obtain such reports.</li>
<li>if you are invited to the RentEase Service, such as from another User, the person who invited you can submit personal information about you such as your email address or other contact information.</li>
<li>to the extent permitted by applicable law, we may receive additional information about you, such as references, demographic data, or information to help detect fraud and safety issues from third party service providers and/or partners, and combine it with information we have about you.</li>
</ol>
<li><strong>Sharing and Disclosures</strong>.</li>
<ol>
<li><strong>Sharing With Your Consent or at Your Direction</strong>. Where you provide consent, we share your information as described at the time of consent, such as when authorizing a third-party application or website to access your RentEase account or participating in promotional activities by RentEase partners or third parties. Where permissible under applicable law, we may use certain information about you, such as your email address, de-identify it, and share it with social media platforms, to generate leads, drive traffic to RentEase, or otherwise promote our products and services.</li>
<li><strong>Public Information</strong>. You can make certain information publicly visible to others, such as:</li>
<ol>
<li>Your public profile page, Listings that include information such as approximate or precise location, description, calendar availability, photos, and additional information you choose to share.</li>
<li>Reviews, ratings, and other public feedback.</li>
<li>We may display parts of any content you make available to the public like Listing details on third-party sites, platforms, and apps.</li>
<li>Information you share publicly on the RentEase Service may be indexed through third-party search engines.&nbsp;</li>
</ol>
<li><strong>Sharing Between Users.</strong> To help facilitate leases, Listings or other interactions between users, we may need to share certain information such as:</li>
<ol>
<li>When a Personal Property Lease request is made, when there is a co-User, or a dispute is submitted, certain information may be shared between User(s), including profile, name, cancellation history, review information, age (unless prohibited by applicable law), dispute outcome (when applicable), and other information you choose to share and submit. When a Personal Property Lease is confirmed, additional information is shared to assist with coordinating the Personal Property Lease, like address and phone number. When you as a User have a confirmed Personal Property Lease, certain information is shared with the other user(s) to coordinate the rental, such as your profile, full name, phone number, and Listing address.</li>
<li>When you as a User invite another person to the RentEase Service, you authorize that person to access and update your information and User content, including, but not limited to, certain information like your full name, phone number, address, email address, and other relevant information.</li>
<li>When you as a User invite additional Users to a Listing, your name, User name, Listing details, address, and other related information will be shared with each additional User.</li>
</ol>
<li><strong>Sharing With Service Providers</strong>. We share personal information with affiliated and unaffiliated service providers to help us run our business and for their compliance purposes, including service providers that help us: (i) verify your identity or authenticate your identification documents, (ii) check information against public databases, (iii) conduct background or police checks, fraud prevention and risk assessment, (iv) perform product development, maintenance and debugging, (v) allow the provision of the RentEase Services through third-party platforms and software tools, (vi) provide customer service, advertising. Information we share with third-party providers will be kept secure in conjunction with our use of your information.&nbsp;</li>
<li><strong>Business Transfers</strong>. If RentEase undertakes or is involved in any merger, acquisition, reorganization, sale of assets, bankruptcy, or insolvency event, then we may sell, transfer, or share some or all of our assets, including your information in connection with such transaction or in contemplation of such transaction (e.g., due diligence).&nbsp;</li>
</ol>
<li><strong>Complying With Law, Responding to Legal Requests</strong>. We may disclose your information to courts, law enforcement, governmental or public authorities, tax authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or where disclosure is reasonably necessary to: (i) comply with our legal obligations, (ii)&nbsp; comply with a valid legal request (such as a subpoena or court order) or to respond to claims asserted against RentEase, (iii) respond to a valid legal request relating to a criminal investigation to address alleged or suspected illegal activity, or to respond to or address any other activity that may expose us, you, or any other of our users to legal or regulatory liability, (iv) enforce and administer our agreements with Users, including our User Agreement, or (v)&nbsp; protect the rights, property or personal safety of RentEase, its employees, or its Users.&nbsp;</li>
<ol>
<li>Where appropriate, we may notify users about legal requests unless:</li>
<ol>
<li>providing notice is prohibited by the legal process itself, by court order we receive, or by applicable law, or&nbsp;</li>
<li>we believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or increase a risk of fraud upon or harm to RentEase, our users, or expose RentEase to a claim of obstruction of justice.</li>
</ol>
<li>In jurisdictions where RentEase facilitates or requires a registration, notification, permit, or license application or number of a User with a governmental authority, we may share information of participating Users with the relevant authority, both during the application process, when the Listing is published, and periodically thereafter, such as the User&rsquo;s full name and contact details, tax identification number, registration, permit, or license number, Listing details subject to applicable laws.</li>
</ol>
<li><strong>How We Use The Information We Collect</strong>.</li>
<ol>
<li><strong>Support and Improve the Development of the RentEase Service</strong>. Such use includes:</li>
<ol>
<li>detect and prevent fraud, spam, abuse, security and safety incidents, and other harmful activity,</li>
<li>facilitating location sharing, expanding communications options among users, and creating different posting options,</li>
<li>conduct security investigations and risk assessments,</li>
<li>verify or authenticate information provided by you,</li>
<li>conduct checks against databases and other information sources, including background or police checks,</li>
<li>comply with our legal obligations, protect the health and well-being of our Users,</li>
<li>resolve disputes with our Users, including sharing information with your co-User or additional users about disputes,</li>
<li>enforce our agreements with third parties,</li>
<li>comply with law, respond to legal requests, prevent harm, and protect our rights,</li>
<li>enforce our User Agreement and other policies, and</li>
</ol>
<li><strong>Provide, Personalize, Measure, and Improve our Advertising and Marketing</strong>. For example to:</li>
<ol>
<li>send you promotional messages, marketing, advertising, and other information based on your preferences and social media advertising through social media platforms,</li>
<li>personalize, measure, and improve our advertising,</li>
<li>administer referral programs, rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by RentEase or its third-party partners,</li>
<li>analyze characteristics and preferences to send you promotional messages, marketing, advertising, and other information that we think might be of interest to you.</li>
</ol>
</ol>
<li><strong>Other Important Information</strong>.</li>
<ol>
<li><strong>Changes To This Privacy Policy</strong>. We reserve the right to modify this Privacy Policy at any time in accordance with applicable law. If we do so, we will post the revised Privacy Policy. If you disagree with the revised Privacy Policy, you can cancel your Account. If you do not cancel your Account before the date the revised Privacy Policy becomes effective, your continued access to or use of the RentEase Service will be subject to the revised Privacy Policy.</li>
<li><strong>Data Retention and Deletion</strong>. We will retain the information we collect for as long as you use the RentEase Service. Information related to taxes or as otherwise required by any government agency will be retained by us in accordance with applicable laws.&nbsp;</li>
<li><strong>Contact Us</strong>. If you have any questions, concerns or other inquiries related to this privacy policy, you may contact us at ________</li>
<li><strong>Your Consent.</strong> In using the Rentease Service in any capacity, you explicitly consent to the collection and sharing of your information in the manner stated in this policy.&nbsp; If you wish to revoke your consent you may do so by canceling your RentEase account. Upon cancellation of your account, all information collected by us will be deleted. Once we delete your personal information, we can no longer provide you with access to the information.</li>
</ol>
</ol>
        </div>
    )
}