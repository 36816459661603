import { Button, Container } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";

export default function NewUserAdd(props) {

    const history = useHistory();

    return (
        <Container style={{ textAlign: 'center' }}>
            <h1>Welcome to RentEase!</h1><br />
            <h2>Please fill out the following : </h2><br />
            <Link to='/personalinfo'>
                <Button variant="outlined" className="buttonBackground">Personal Info</Button>
            </Link><br /><br /><br />

            <Link>
                <Button variant="outlined" className="buttonBackground">Profile Picture</Button>
            </Link><br /><br /><br />

            <Link>
                <Button variant="outlined" className="buttonBackground">Notifications</Button></Link>
                <br /><br /><br />

        </Container>
    )
}