import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	headerTitle:{
		textAlign: 'center'
	},
	iconSize: {
		width: 30
	},
	root: {
		width: '100%',
		background:'transparent',
		border: '1px solid grey',
		marginBottom:'20px',
		borderRadius:'12px',
		boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
	  },
	  heading: {
		fontSize: 'large',
		fontWeight:'bold'
	  },
	accordRoot:{
		background:'transparent'
	},
	accordSum:{
		background: 'black',
		borderRadius: '15px',
	},
	lb:{
		whiteSpace: 'pre-line'
	}
 }));

export default function MyAccount() {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
	window.scrollTo(0, 0)
  }, [])
  const helpInfo = [
	{
		tit:"How do payments work?", desc:'At Rentease our priority is that the transaction process will be secure and convenient.\n\n Rentease will store no bank account or credit card information on our site, all that information will be stored with our partner Stripe.\n\n As you set up your user account with Rentease you will setup your transaction account with Stripe.  For more detailed information on the process, security, and convenience of the Stripe transaction process please follow this link.',
	},
	{
		tit:"Cancellations",desc:`Renters may cancel their rental through our site or mobile app, and the cancelation is effective immediately.  Whether the renter receives a full refund , partial refund, or receives no refund depends on the owners discretion.  The total amount refunded will depend on when the renter cancels the rental request, length of rental and type of rental.\n\nRenters may cancel free of charge up to 24 hours before the rental time begins.  Renters who book within 24 hours of rental pickup have one hour after booking to cancel for free.  If a renter wants to cancel a rental we recommend they notify the owner as soon as possible via Rentease messaging and to process the cancellation themselves through the Rentease website or app.`
	},
	{
		tit:"Trust & Safety",desc:"Rentease is a global community built on trust and inclusion.  We’re dedicated to providing a safe and secure marketplace for renters, and owners, and we are here to support you 24/7 whenever you have questions or need a hand.\n\nTrust\nTrust is essential to a marketplace that works.  With secure payments, fraud protection and authentic reviews.  We make sure all members of our community can provide equipment and rent with confidence.\n\nSecurity\nKeeping your account safe and secure is a top priority.  From payment protection to fraud prevention, we have tools in place to reduce risks in financial transactions and maintain trust between parties that may not know each other.\n\nSupport\nWe have teams on call 24 hours a day, seven days a week to support owners, and renters who require assistance or have questions.  If there is anything you need, please get in touch.\n\n\nSafety\nRentease offers a range of tools and resources that protect the privacy and safety of renters and owners.  We support owners by educating them about best practices for providing clean and well maintained equipment, and we use technology to verify the identities of owners and renters whenever possible.  We advise our community to follow state and local laws that may apply"
	},
	{
		tit:"Your Listings",desc:"You are allowed to list anything you would like, with the exception of firearms or anything that is considered illegal in your state.  You may list things and you are in charge of how long they may have it for and how much they rent it for.  Rentease gets a certain percentage of the money but most will be given to you as the lister.\n\nRentease does all they can to make sure everything runs smoothly and efficiently on both sides: the lister and the customer.  Please let us know if there is anything we can do to make your experience greater."
	},
	{
		tit:"Rental Help",desc:"There is a search bar on the bottom left corner and you can search for whatever item you may need.  You may rent an item for ____ period of time.  When renting an item it is up to the lister whether or not they want you to have renters insurance and what the daily/ weekly rate is. Item must be paid for prior to renting it."
	},
	{
		tit:"Your Account",desc:"When entering the app there is an icon that says “your account” you click on it. You may create an account (if you don’t have one) or sign in (if you have already created an account). With an account you can rent an item or list items to be rented.\n\nRentease strives to make the platform as easy to use as possible.\n\nTo delete an account please email your information to : service@rentease.com"
	},
	{
		tit:"How to Get Paid",desc:"At Rentease our priority is that the transaction process will be secure and convenient.  Rentease will store no bank account or credit card information on our site, all that information will be stored with our partner Stripe.  As you set up your user account with Rentease you will setup your transaction account with Stripe.  For more detailed information on the process, security, and convenience of the Stripe transaction process please follow this link."
	},
  ]
  const handleClickTerms = () => {
	// Add your logic here
	history.push("/terms")
  };
  const handleClickPrivacy = () => {
	// Add your logic here
	history.push("/privacy")
  };
  return (
    <div>
    <div className="helpPageHead">
				<div style={{paddingTop:'50px',fontWeight:'bolder',fontWeight:'36px',fontWeight:'bolder',fontSize:'xxx-large',background:'transparent'}}>How can we help?</div>
				<div style={{fontSize:'small',fontWeight:'bold',maxWidth:'700px',paddingTop:'20px',margin:'auto',background:'transparent'}}>
				Click the dropdowns below to learn about anything from our payment process to how cancellations work.
				</div>
			</div>
	<Container maxWidth="lg" className="pad5">
	<Container maxWidth="md" style={{marginBottom:'50px'}}>
	{helpInfo.map((item) => (
	<div className={classes.root}>
      <Accordion
	  className={classes.accordRoot}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
		  className="accordSum"
        >
          <Typography className={classes.heading} inline>{item.tit}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.lb} >
				{item.desc}
				{item.tit == "How do payments work?" ? 
				<div>
		  			<br/><Button style={{color:'#585AF8',fontWeight:'bold',border:'1px solid grey'}}>Stripe</Button>
				  </div>
			  :
			  <></>
			}
          </Typography>

        </AccordionDetails>
      </Accordion>
    </div>
	))}
		<div className={classes.root}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
		  className="accordSum"
		  onClick={handleClickTerms} // Add onClick handler here
        >
          <Typography className={classes.heading} inline>User Agreement</Typography>
        </AccordionSummary>
		</div>
		<div className={classes.root}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
		  className="accordSum"
		  onClick={handleClickPrivacy} // Add onClick handler here
        >
          <Typography className={classes.heading} inline>Privacy Policy</Typography>
        </AccordionSummary>
		</div>
	</Container>
	<div className="hrCont">
            <hr className="hrLine"/>
        </div>
		<div className="helpPageHead">
				<div style={{paddingTop:'30px',fontWeight:'bolder',fontWeight:'36px',fontWeight:'bolder',fontSize:'xx-large',background:'transparent'}}>Renting with us.</div>
		</div>
		<div style={{display:'grid',gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',margin:'30px'}}>
			<div className="fb mb50">
				<div className="mright10">
					<img src={window.location.origin+"/svg/Icon1.svg"} width="50" height="50"/>
				</div>
				<div>
					<b>Leasing on Rentease</b>
					<div>Pickup/Delivery</div>
					<div>Gifting & Favorites</div>
					<div>Renting Safely</div>
				</div>
			</div>
			<div className="fb mb50">
				<div className="mright10">
					<img src={window.location.origin+"/svg/Icon2.svg"} width="50" height="50"/>
				</div>
				<div>
					<b>Rental Payment</b>
					<div>Checkout</div>
					<div>Payment Options</div>
				</div>
			</div>
			<div className="fb mb50">
				<div className="mright10">
					<img src={window.location.origin+"/svg/Icon3.svg"} width="50" height="50"/>
				</div>
				<div>
					<b>Your Rentals</b>
					<div>Order Issues</div>
					<div>Money Returns</div>
				</div>
			</div>
			<div className="fb mb50">
				<div className="mright10">
					<img src={window.location.origin+"/svg/Icon4.svg"} width="50" height="50"/>
				</div>
				<div>
					<b>Your RentEase Account</b>
					<div>Settings & Preferences</div>
					<div>Signing In</div>
					<div>Contacting RentEase</div>
					<div>Account Privacy</div>
				</div>
			</div>
		</div>
		</Container>
		<div className="whiteText" style={{background:'#58595B',padding:'50px 0px'}}>
			<div style={{background:'transparent',marginBottom:'20px'}}>
					<div style={{textAlign:'center',paddingTop:'30px',fontWeight:'bolder',fontWeight:'36px',fontWeight:'bolder',fontSize:'xx-large',background:'transparent'}}>Didn’t Find what you needed? Try these.</div>
			</div>
			<div style={{background:'transparent',textAlign:'center',marginBottom:'70px'}}>
				<Button class="btn btn--primary btn--medium" style={{margin:'20px 0px',width:'300px'}}>Help With a Rental</Button><br/>
				<Button variant="outlined" style={{color:'white',border:'2px solid white',width:'300px'}}>Contact Us</Button>
			</div>
			<div style={{background:'transparent',display:'grid',gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',marginTop:'15px'}}>
				<div className="mycard">
					<b>Read our policies</b><br/>
					<span>Get the ins and outs of renting and leasing on RentEase</span>
				</div>
				<div className="mycard">
					<b>Ask an expert</b><br/>
					<span>Learn by getting in touch with one of our RentEase Experts</span>
				</div>
				<div className="mycard">
					<b>See how you’re protected</b><br/>
					<span>Find out more about safety and security in our marketplace</span>
				</div>
			</div>
		</div>
    </div>
  );
}