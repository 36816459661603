import React, {useState,useEffect} from "react";
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { signInWithGoogle, signInWithEmail }  from './config'
import { useContext } from "react";
import { UserContext } from "./providers/UserProvider";
import { useHistory } from "react-router-dom";
import Spinner from './components/Spinner'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    marginTop:'50px'
  },
  textField: {
  },
  centerF: {
	textAlign: 'center'
  },
  googleButt: {
	height: '36px',
  padding:'0px 20px'
  },
  googleImg:{
	height: '26px',
	margin: '0px 8px'
  }
}));

const Login = () => {
	  const classes = useStyles();
	  const user = useContext(UserContext);
	  const history = useHistory()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);

	const emailVal = (val) => {
    setEmail(val.target.value);
  }
  const passVal = (val) => {
    setPassword(val.target.value)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    }, [])
return(
    <div className={classes.root}>
      <Container maxWidth="sm" style={{border:'1px solid',margin:'20px',borderRadius:'15px',padding:'10px',display:'flex',justifyContent:'center',marginTop:'50px',height:'auto'}}>
        <div> 
          Login Coming Soon!
        </div>
      </Container>
    </div>
  )
  /*
if(user){
	history.push('/myaccount');
		return (
			<div>
				<Spinner/>
			</div>
		)
}else{
  return (
    <div>
				<Container maxWidth="sm" className="loginPanel darkBorder">
      <h1 className="titleHeaderText">Login or Sign up</h1>
      <div >
        {error !== null && <div>{error}</div>}
        <form className="">
		<TextField
          id="outlined-full-width"
          label="User Email"
          placeholder="Enter email here"
          helperText="Please Create an account first."
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={email}
          onChange={emailVal}
          inputProps={{className:"textInputStyles"}}
        />
        <TextField
          id="outlined-full-width"
          label="User Password"
          placeholder="Enter password here"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={password}
          onChange={passVal}
          inputProps={{className:"textInputStyles"}}
          type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              //onChange={someChangeHandler}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
        />
		<Button className="buttonBackground" variant="outlined" onClick = {(event) => {signInWithEmail(event, email, password)}} variant="outlined">Log In</Button>
        </form>
        <p className="centerPad">or</p>
        <Button  className="buttonBackground" variant="outlined" onClick={signInWithGoogle} variant="outlined"><img alt="google login" className={classes.googleImg}  src="/images/google-icon-logo-svg-vector.svg"/>Log In With Google</Button>
		<br/>
		<br/>
        <p>
          Don't have an account?{" "}
          <Link to="signup" >
            Sign up here
          </Link>{" "}
          <br />{" "}
          <Link to = "forgotpassword">
            Forgot Password?
          </Link>
        </p>
      </div>
      <br/>
      <br/>
      <div>
        By logging in, or creating an account you agree to <Link to={{ pathname: '/terms'}}>RentEase Terms and Conditions.</Link>
      </div>
	  </Container>
    
    </div>
    
  );
}*/
};
export default Login;