import * as React from 'react';
import ErrorIcon from '@material-ui/icons/Error';

export default function DefaultAlert(props){
    var alertText = props.text
    const showState = props.toggleErrorState
    if(showState){
        return(
            <div className="fb ac" style={{padding:'10px', border:'2px solid red',marginBottom:'10px'}}>
                <ErrorIcon style={{color:'red',marginRight:'10px'}} />
                <div>{alertText}</div>
            </div>
        )
    }else{
        return <></>
    }

}