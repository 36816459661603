import React, {useState , useEffect } from "react";
import {
  InstantSearch,
  Pagination,
  Highlight,
  Hits,
  connectHierarchicalMenu,
  connectStateResults
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import './Search.css';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DirectionsIcon from '@material-ui/icons/Directions';
import ListIcon from '@material-ui/icons/List';
import {
  GoogleMapsLoader,
  GeoSearch,
  Control,
  CustomMarker,
} from 'react-instantsearch-dom-maps';
import { Configure } from 'react-instantsearch-dom';
import { Button } from "@material-ui/core";
import {Helmet} from "react-helmet";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { connectSearchBox } from 'react-instantsearch-dom';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    margin: '35px 10px 6px 10px'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    color:'#326fa2;'
  },
  divider: {
    height: 28,
    margin: 4,
  },
  mapButt: {
    float: 'right'
  },  rootBar: {
    display: 'flex',
    alignItems: 'center'
  },
  inputBar: {
    marginLeft: theme.spacing(1),
    flex: 1,
    background:'white'
  },
  iconButtonBar: {
    padding: 10,
    color:'#326fa2;'
  },
  dividerBar: {
    height: 28,
    margin: 4,
  },
}));

function formatCategoryURL(category){
  var c2 = category.replaceAll(" ", "-")
  return c2
} 
function reverseURLCat(name){
  return name.replace("-", " ")
}

export default function SearchDetail(props) {
  const classes = useStyles();
  const [viewType, setViewType] = React.useState(true);
  const [hasCat, setHasCat] = React.useState(false);
  const [catString, setCatString] = React.useState("")
  const [catAttString, setCatAttString] = React.useState("")
  const [titleTtag, setTtag] = React.useState("")

  const history = useHistory()
  var url_string = window.location.href;
  var url = new URL(url_string);
  var searchParm = url.searchParams.get("searchTerm");
  var viewParm = url.searchParams.get("view");
  const [pageUrl, setPageUrl] = React.useState("")
  const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
    <form noValidate action="" role="search">
        <Paper className={classes.rootBar}>
            <InputBase type='text'
              className={classes.inputBar}
              placeholder="Search Rentals"
              inputProps={{ 'aria-label': 'Search Rentals' }}
              //onChange={onChange}
              onKeyDown={enterAction}
              type="search"
              value={currentRefinement}
              onChange={event => refine(event.currentTarget.value)}
            />
            <IconButton className={classes.iconButton} aria-label="search" onClick={searchAction}>
              <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={switchView}>
              <LocationOnIcon />
            </IconButton>
          </Paper>
    </form>
  );
  
  const CustomSearchBox = connectSearchBox(SearchBox);
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY
  );
  var categorydescMap = {'Construction Equipment':{title:'RentEase Construction Eq',
                                    cananicalUrl:'testcanan.com',
                                    catDescription:'Construction Equipment is available.'},
                          'Ag. Equipment':{title:'testtitle',
                                    cananicalUrl:'testcanan.com',
                                    catDescription:'Ag. Equipment is available.'},
                          }
    var titleTagText = "RentEase"
    var descriptionText = "Our platform is your rental business"
    if(props.location.state !== undefined){
      let catSelect = props.location.state.catName
      if(catSelect != undefined && categorydescMap[catSelect] != undefined) 
      descriptionText = categorydescMap[catSelect].catDescription
    }
    useEffect(() => {
      window.scrollTo(0, 0)
  },[]);

      const [inputValue, setInputValue] = React.useState('')

      var searchTerm = ""
      const searchAction = (event) => {
        if (inputValue != "") {
          history.push('/search?searchTerm=' + inputValue)
        }
      };
      const enterAction = (event) => {
        if (event.key === 'Enter') {
          if (inputValue != "") {
            history.push('/search?searchTerm=' + inputValue)
          }
        }
      };
      const onChange = (event) => {
        searchTerm = event.target.value
        setInputValue(searchTerm);
      };
      const mapSearch = () =>{
        history.push('/search?view=map')
      }

      useEffect(() => {
        window.scrollTo(0, 0)
      },[])

  useEffect(() => {
    if(viewParm == "map"){
      setViewType(false);
    }

    if(props.location.state != undefined){
      var category = props.location.state.catName
      if (category != null && category != "" && category != "Everything!" ){
        setCatString(category);
        setCatAttString('category:"'+category+'"')
        setHasCat(true)
        setPageUrl(window.location.href)
        setTtag(category)
      }
    }else{
      if(url.href.includes('search/')){
        var category = url.href.split('/').pop()
        category = reverseURLCat(category)
        if(searchParm != "" || searchParm != undefined || searchParm != null){

        }
        if (category != null && category != "" && category != "Everything!" ){
          setCatString(category);
          setCatAttString('category:"'+category+'"')
          setHasCat(true)
        }
      }
      if(url.href.includes('category/')){
        var category = url.href.split('/').pop()
        category = reverseURLCat(category)
        if (category != null && category != "" && category != "Everything!" ){
          setCatString(category);
          setCatAttString('category:"'+category+'"')
          setHasCat(true)
        }
      }
    }
	}, []);
  const Hit = ({ hit }) => (
    <Link to={{pathname: formatCategoryURL('/rental/'+hit.rentalTitle),
      state: { hit }}}>
      <article className="hit" itemScope itemType="http://schema.org/Product">
        <header className="hit-image-container">
          <img itemprop="image" src={hit.mainImage} alt={hit.name} className="hit-image" />
        </header>

        <div className="hit-info-container">
          <p className="hit-category" itemProp="category">{hit.category[0]}</p>
          <h1 itemProp="name" style={{padding:'10px 0px',fontSize:16}}>
            {hit.rentalTitle}
          </h1>
          <div itemprop="description" style={{display:'none'}}>{hit.rentalDesc}</div>

          <footer>
            <p itemProp="offers" itemScope itemType="http://schema.org/Offer">
              <span className="hit-em" itemProp="priceCurrency" content="USD">$</span>{' '}
              <strong itemProp="price" content={hit.fee_d}>{hit.fee_d}/day</strong>{' '}<br/>
              <link itemProp="url" href={"https://www.rentease.com/rental/"+hit.rentalTitle} />
              <span className="hit-em hit-rating">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="8"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#e2a400"
                    fillRule="evenodd"
                    d="M10.472 5.008L16 5.816l-4 3.896.944 5.504L8 12.616l-4.944 2.6L4 9.712 0 5.816l5.528-.808L8 0z"
                  />
                </svg>{hit.rentalRevNumber == '' || hit.rentalRevNumber == undefined ? 'None' : hit.rentalRevNumber.toFixed(1) }
              </span>
              {hit.rentalRevNumber == '' || hit.rentalRevNumber == undefined ? 
                (<div itemprop="aggregateRating" itemtype="https://schema.org/AggregateRating" itemscope>
                <meta itemprop="reviewCount" content="0" />
                <meta itemprop="ratingValue" content="0" />
              </div>)
              :
              (<div itemprop="aggregateRating" itemtype="https://schema.org/AggregateRating" itemscope>
                <meta itemprop="reviewCount" content={hit.rentalRevNumber.toFixed(1)} />
                <meta itemprop="ratingValue" content={hit.reviews.length}  />
              </div>)
              }
            </p>
          </footer>
        </div>
      </article>
    </Link>
  );

  const switchView = () => {
    if(viewType){
      setViewType(false)
    }else{
      setViewType(true)
    }
  }

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) =>
      searchResults && searchResults.nbHits !== 0 ? (
        children
      ) : (
        <div style={{minHeight:200}}>No results</div>
      ) 
  );

  
  return (
    <>
    <Helmet>
        <title>{titleTtag}</title>
        <link rel="canonical" href={pageUrl} />
        <meta name="description" content={descriptionText} />
    </Helmet>
    <InstantSearch
      indexName="rental_items"
      searchClient={searchClient}
      onSearchStateChange={()=>{
        window.scrollTo(0, 0)
      }}
    >
      {hasCat ? 
        <Configure
          
          filters={catAttString}
        aroundLatLngViaIP={true}
        hitsPerPage={8}
        />
      :
        <Configure
        aroundLatLngViaIP={true}
        hitsPerPage={8}
      />
      }
      <header>
        <CustomSearchBox/>
        {catString != null ? <div style={{margin: '15px',float: 'left'}}>{catString}</div> : <div></div>}
      </header>
      { viewType ?
        <main className="container">
          <section className="container-results">
              <Results>
                <Hits hitComponent={Hit} />
              </Results>
            <footer className="container-footer">
              <Pagination
                style={{display:'flex'}}
                padding={2}
                showFirst={false}
                showLast={false}
                translations={{
                  previous: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M9 5H1M5 9L1 5l4-4" />
                      </g>
                    </svg>
                  ),
                  next: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M1 5h8M5 9l4-4-4-4" />
                      </g>
                    </svg>
                  ),
                }}
              />
            </footer>
          </section>
        </main>
        :
        <div className="mapContainer">
        <div style={{ height: 400,marginBottom:"100px",background:'none !important'}}>
          <GoogleMapsLoader apiKey="AIzaSyBzkXrXbhh_Fi5iJE3PDq7DJvTcyvSy1UM">
            {google => (
              <GeoSearch google={google} >
                {({ hits }) => (
                  <div>
                    <Control />
                    {hits.map(hit => (
                      <Link to={{ pathname: '/rental', state: { hit } }}>
                      <CustomMarker key={hit.objectID} hit={hit}>
                        <div style={{textAlign:'center',border:'2px solid black', background:'white',color:'black',borderRadius:'10px',padding:'5px 10px'}}>${hit.fee_d}</div>
                     
                      </CustomMarker>
                      </Link>
                    ))}
                  </div>
                )}
              </GeoSearch>
            )}
          </GoogleMapsLoader>
        </div>
        </div>
      }
    </InstantSearch>
    </>
  )
}