import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
} from "./FooterStyles";
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Link } from "react-router-dom";

const Footer = () => {

	function openAppleStore(){
		window.open("https://apps.apple.com/app/id1621378785", "_blank")
		}
	
		function openAndroidStore(){
		  window.open("https://play.google.com/store/apps/details?id=com.renteasereactnative1.production&hl=en_US&gl=US", "_blank")
		}
const comingSoon = ()=>{
	alert("Coming Soon!")
}
return (
	<>
	      <div>
      <div style={{backgroundColor:'#58595B',width:'100%',height:'315px',marginTop:'40px',position:'absolute',left:0,right:0,zIndex:0}}></div>
      <div style={{display:'grid',gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',marginTop:'30px'}}>
        <div className="downloadAppImage">
				<img style={{borderRadius:'20px',zIndex:1,boxShadow:'-.0em 0 .8em black'}} src={window.location.origin+"/images/AppExample.png"} width="250" height="500"/>
        </div>
        <div className="downloadAppContainer">
          <div style={{color:'white',fontSize:'27px',fontWeight:'bolder',background:'transparent'}}>Download the RentEase app today.</div>
          <div style={{display:'flex',marginRight:'10px',background:'transparent'}}>
          <div style={{background:'transparent',marginLeft:'-10px',cursor:'pointer'}} onClick={()=>{openAndroidStore()}}><img src={window.location.origin+"/images/google-play-badge.png"} width="180" height="70"/></div>
          <div style={{paddingRight:'10px',background:'transparent',cursor:'pointer'}} onClick={()=>{openAppleStore()}}><img src={window.location.origin+"/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"} width="140" height="70"/></div>
          </div>
        </div>
      </div>
      </div>
	<Box>
	<Container>
		<Row>
		<Column>
            <div><img src={window.location.origin+"/images/RentEase-Namestyle.svg"} width="220" height="50" style={{marginLeft:'-40px',marginBottom:'10px'}}/></div>
			<Heading>Company</Heading>
			<Link to="/help" className="linkDec">Resources</Link>
			<Link to="/terms" className="linkDec">User Agreement</Link>
			<Link to="/privacy" className="linkDec">Privacy Policy</Link>
		</Column>
		<Column>
			<Heading>Links</Heading>
			<Link to="/help" className="linkDec">Help</Link>
		</Column>
		<Column>
			<Heading>Get In Touch</Heading>
			<FooterLink href="#">Info@rentease.com</FooterLink>
            <div>
			<FooterLink href="#">
			<TwitterIcon onClick={comingSoon}/>
            </FooterLink>
            <FooterLink href="#">
            <FacebookIcon onClick={comingSoon}/>
            </FooterLink>
            <FooterLink href="#">
            <LinkedInIcon onClick={comingSoon}/>
            </FooterLink>
            </div>
		</Column>
		</Row>
        <div style={{margin:'80px 0px 30px 0px'}}>
        ©2024 ALL RIGHTS RESERVED RENTEASE
        </div>
	</Container>
	</Box>
	</>
);
};
export default Footer;
