import React,{useState,useEffect} from 'react'
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core'
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { firestore } from '../../../config'


export default function Notifications () {
    const [allowEmails,setAllowEmail] = useState(false)
    const history = useHistory()
    const user = useContext(UserContext);
    const [notificationOn,setNotificationOn] = useState(false)


    const updateChange = () =>{
        if(notificationOn){
            setNotificationOn(false)
            var userref = firestore.collection("users").doc(user.uid)
            return userref.update({
                notfiyEmail: false
            })
            .then(() => {
                console.log("Document successfully updated!");
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
            });
        }else{
            setNotificationOn(true)
            var userref = firestore.collection("users").doc(user.uid)
            return userref.update({
                notfiyEmail: true
            })
            .then(() => {
                console.log("Document successfully updated!");
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
            });
            
        }
    }
    useEffect(() => {
        if(user){
        const cityRef = firestore.collection('users').doc(user.uid);
        const doc = cityRef.get().then(res => {
            if(res.data().notfiyEmail){
                setNotificationOn(true)
            }
        });
        }else{
          history.push('/login');
        }
      }, []);

    return (
        <>
        <h1 style={{textAlign:'center'}}>Notifications</h1>
				<Paper>
					<MenuList>
						<MenuItem>
							<ListItemText primary="Emails" />
							<ListItemIcon>
                            <Switch
                                    checked={notificationOn}
                                    onChange={updateChange}
                                    name="checkedA"
                                    color="primary"
                                />
							</ListItemIcon>
						</MenuItem>
						<Divider />
                    </MenuList>
                </Paper>
        </>
    )
}