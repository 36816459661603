import React , {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '15px',
    marginTop: '15px',
    paddingBottom:'50px',
    maxWidth:'1000px',
    margin:'30px auto',
    background:'transparent',
  },
  gridList: {
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    background:'transparent'

  },
  title: {
    fontSize: '16px',
    whiteSpace:'normal',
    background:'transparent',
    textAlign:'center',
    fontWeight:'bold',
    color:'white'
  },

  imagecont: {
  }
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export const tileData = [ 
  {
    img: 'images/RentEase-Mark.svg',
    title: 'Everything!',
    key: 122
  },
  {
    img: 'images/Construction-Equipment.jpg',
    title: 'Construction Equipment',
    key: 1
  },
  {
    img: 'images/Ag-Equipment.jpg',
    title: 'Ag. Equipment',
    key: 2
  },
  {
    img: 'images/Sporting-Goods.jpg',
    title: 'Sporting Goods',
    key: 3
  },
  {
    img: 'images/Tools.jpg',
    title: 'Tools',
    key: 4
  },
  {
    img: 'images/Trailers-Campers-2.jpg',
    title: 'Trailers / Campers',
    key: 5
  },
  {
    img: 'images/Party-Goods-Weddings-2.jpg',
    title: 'Party Goods / Weddings',
    key: 6
  },
  {
    img: 'images/Clothing-2.jpg',
    title: 'Clothing, Shoes, Accessories',
    key: 7
  }/*,
  {
    img: 'images/Electronics-2.jpg',
    title: 'Electronics',
    key: 8
  },
  {
    img: 'images/Baby-goods-2.jpg',
    title: 'Baby Goods',
    key: 9
  },
  {
    img: 'images/Home-&-Garden.jpg',
    title: 'Home and Garden',
    key: 10
  },
  {
    img: 'images/Musical-Instruments.jpg',
    title: 'Musical Instruments',
    key: 11
  },
  {
    img: 'images/Photography.jpg',
    title: 'Photography',
    key: 12
  },
  {
    img: 'images/Luggage.jpg',
    title: 'Luggage',
    key: 13
  },
  {
    img: 'images/Everything-Else.jpg',
    title: 'Everything Else',
    key: 14
  }*/
];

function formatCategoryURL(category){
  return category.replace(" ", "-").replace("/", "")
} 

export default function TopCatDisplay() {
  const classes = useStyles();
  const history = useHistory();
  const [screenSize, setScreenSize] = useState(window.innerWidth)

  const clickImage = (val) => {
    history.push({
      pathname: 'category/' + formatCategoryURL(val),
      state: { catName: val }
    })
  }
  const viewAll = () => {
    history.push('/topcategories')
  }

  function resizeFun() {
    setScreenSize(window.innerWidth);
  }

  window.onresize = resizeFun

  return (
    <div className={classes.root}>
    <div style={{display:'flex',paddingBottom:'10px'}}>
        <div className="hrCont">
            <hr className="hrLine"/>
        </div>
        <div style={{whiteSpace: 'nowrap', fontWeight:'bold',fontSize:'x-large',padding:'8px'}}>Top Categories</div>
    </div>
      <GridList className={classes.gridList} cols={screenSize < 600 ? 2 : 3}>
        {tileData.map((tile) => (
          <GridListTile key={'upper'+tile.key} className="catCatSlist">
            <meta itemProp='image' content={'https://rentease.com/'+tile.img}/>
            <img className="hoverImage" src={tile.img} alt={tile.title} onClick={() => clickImage(tile.title)} style={{width:'100%',height:'100%'}}/>
            <GridListTileBar
              title={tile.title}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
            />
          </GridListTile>
        ))}
      </GridList>
      <div style={{display:'flex',justifyContent:'center'}}>
          <Link className="btn-link" onClick={viewAll}>
							<Button className="buttonBackground" variant="outlined">View All</Button>
						</Link>
      </div>
    </div>
  );
}