import react from 'react'
import { Button, Container } from '@material-ui/core';
import { useHistory } from 'react-router';

export default function TranDetails(props){
    const history = useHistory()

    const convertSchedMoney = (amountString) => {
        var intAmount = parseInt(amountString);
        var dividAmount = intAmount///100;
        return dividAmount.toFixed(2);     
        }


    var rentalType = ""
    var rentalTypeAmount = ""
    var cardType = ""
    var last4 = ""
    var serviceFee = ""
    var taxAmount = "TBD"
    var totalCharged = ""
    var maxDeposit = ""
    var scheduleId = ""

    if (props.location.query) {
        console.log(props.location.query)
        var schedd = props.location.query.scheduleDat
        rentalType = schedd.renterData.rentalType
        rentalTypeAmount = schedd.rent_dur;
        cardType = schedd.renterData.pMbrand
        last4 = schedd.renterData.pMlast4
        serviceFee = schedd.paymentObject.rentServiceFee
        scheduleId = props.location.query.schedIdent
        if(schedd.renterData.status != "Pending"){
            taxAmount = schedd.taxData.totalTax
        }
        totalCharged = schedd.paymentObject.totalCharge
        //maxDeposit = convertSchedMoney(schedd.depositFee)

	}
    console.log(rentalType)

    return (
        <>
            <Container maxWidth="sm">
                <h1>Scheduled Rental Tranaction Details</h1>
                <div>
                    <div>Duration</div>
                    <div className="divindentleft">
                        <div className="flexdiv">
                            <div>Type:</div><div>{rentalType}</div>
                        </div>
                        <div className="flexdiv">
                            <div>Amount:</div><div>{rentalTypeAmount}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>Payment</div>
                    <div className="divindentleft">
                        <div className="flexdiv">
                            <div>Type:</div><div>{cardType}</div>
                        </div>
                        <div className="flexdiv">
                            <div>Last 4:</div><div>{last4}</div>
                        </div>
                        <div className="flexdiv">
                            <div>Service Fee:</div><div>${serviceFee}</div>
                        </div>
                        <div className="flexdiv">
                            <div>Tax:</div><div>${taxAmount}</div>
                        </div>
                        <div className="flexdiv">
                            <div>Total Charged:</div><div>${totalCharged}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>Schedule Indentifier</div>
                    <div className="divindentleft">
                        <div className="flexdiv">
                            <div>{scheduleId}</div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}