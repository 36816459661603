import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '15px',
    marginTop: '15px'
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: 'white',
    fontSize: '13px;'
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  imagecont: {
    width: '350px'
  }
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export const tileData = [ 
  {
    img: 'images/RentEase-Mark.svg',
    title: 'Everything!',
    key: 122
  },
  {
    img: 'images/Construction-Equipment.jpg',
    title: 'Construction Equipment',
    key: 1
  },
  {
    img: 'images/Ag-Equipment.jpg',
    title: 'Ag. Equipment',
    key: 2
  },
  {
    img: 'images/Sporting-Goods.jpg',
    title: 'Sporting Goods',
    key: 3
  },
  {
    img: 'images/Tools.jpg',
    title: 'Tools',
    key: 4
  },
  {
    img: 'images/Trailers-Campers-2.jpg',
    title: 'Trailers / Campers',
    key: 5
  },
  {
    img: 'images/Party-Goods-Weddings-2.jpg',
    title: 'Party Goods / Weddings',
    key: 6
  },
  {
    img: 'images/Clothing-2.jpg',
    title: 'Clothing, Shoes, Accessories',
    key: 7
  },
  {
    img: 'images/Electronics-2.jpg',
    title: 'Electronics',
    key: 8
  },
  {
    img: 'images/Baby-goods-2.jpg',
    title: 'Baby Goods',
    key: 9
  },
  {
    img: 'images/Home-&-Garden.jpg',
    title: 'Home and Garden',
    key: 10
  },
  {
    img: 'images/Musical-Instruments.jpg',
    title: 'Musical Instruments',
    key: 11
  },
  {
    img: 'images/Photography.jpg',
    title: 'Photography',
    key: 12
  },
  {
    img: 'images/Luggage.jpg',
    title: 'Luggage',
    key: 13
  },
  {
    img: 'images/Everything-Else.jpg',
    title: 'Everything Else',
    key: 14
  }
];

export default function SingleLineGridList() {
  const classes = useStyles();
  const history = useHistory();

  const clickImage = (val) => {
    history.push('/category?cat=' + val)
  }

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={1.5}>
        {tileData.map((tile) => (
          <GridListTile key={'upper'+tile.key} style={{maxWidth:'350px'}}>
            <img className={classes.imagecont} src={tile.img} alt={tile.title} onClick={() => clickImage(tile.title)} />
            <GridListTileBar
              title={tile.title}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}