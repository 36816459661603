import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardContainer from './CardContainer'
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import {firestore} from '../../../config'
import firebase from 'firebase'
import {addedTokenData} from './CardContainer'
import Spinner from '../../Spinner'

const useStyles = makeStyles((theme) => ({
    horizCont: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '15px 0px',
        fontSize: '22px'
    },
    horizContTotal: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '15px 0px',
        fontSize: '28px',
        fontWeight: 'bold'
    },
    horizContButton: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: '30px 0px'
    },
    alignCent: {
        textAlign: 'center'
    },
    marginSpace:{
        margin:'15px 0px'
    }
}));

const formatDate = (date) => {
    const dat = new Date(date);
    return (1+dat.getMonth())+'/' + dat.getDate() + '/' + dat.getFullYear()
}
const formatMoney = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
}
export default function CompleteSchedule(props) {
    const history = useHistory()
    const classes = useStyles();
    const [hasSavedCard, setHasSavedCard] = useState(false);
    const [last4, setLast4] = useState('');
    const [taxAmount, setTaxAmount] = useState('');
    const [totalCharge, setTotalCharge] = useState('');
    const [cardBrand, setCardBrand] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [pmid, setPmid] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [loadingPayment, setLoadingPayment] = useState(true);
    const [processingSched, setProcessingSched] = useState(false);
    const [loadingTax, setLoadingTax] = useState(true);
    const user = useContext(UserContext);
    var selectedStartDate = props.location.state.sd;
    var selectedEndDate = props.location.state.ed;
    var rentalData = props.location.state.rdata;
    var pickupTime = props.location.state.pickupTime;
    var rentalType = props.location.state.rtype;
    var dayFee = rentalData.fee_d;
    var weekFee = rentalData.fee_w;
    var halfDFee = rentalData.fee_h;
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    function goBack() {
        history.goBack()
    }   
    if(selectedStartDate == "" || selectedStartDate == undefined || selectedStartDate == null || user == undefined || user == null){
        goBack()
    }
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

    useEffect(() => {
        console.log("------1")
        if(user){
        const schedRef = firestore.collection('users').doc(user.uid);
        const doc = schedRef.get().then(doc => {
            //console.log(doc.data())
            if(doc.data().customerid){
                setLast4(doc.data().paymentInfo.card.last4)
                setCardBrand(doc.data().paymentInfo.card.brand)
                setCustomerId(doc.data().customerid);
                setPmid(doc.data().paymentInfo.card.id);
                setHasSavedCard(true);
                setUserPhone(doc.data().phoneNum);
                setLoadingPayment(false)
            }else{
                setLoadingPayment(false)
            }
        });
        calcTotal();
        var calculateTax = firebase.functions().httpsCallable('calculateTax');
        calculateTax({rental_id: rentalData.objectID, chargeAmount: total}).then((result) => {
            console.log(result)
            console.log(serviceFee)
            console.log(total)
            var totalPlusTax = total + result.data.taxAmount;
            setTaxAmount(result.data.taxAmount)
            setTotalCharge(totalPlusTax)
            setLoadingTax(false)

          }).catch((err) =>{
                alert(err)
                setTaxAmount("TBD")
                setTotalCharge("TBD");
                setLoadingTax(false)
          });	
        }
        }, []);
    const removeSavedCard = () => {
        var initStr = firebase.functions().httpsCallable('deleteCustomerData');
        initStr({custid: customerId}).then((result) => {
                console.log(result);

          }).catch((err) =>{
                alert(err)
          });	
    }

    const date1 = new Date(selectedStartDate);
    const date2 = new Date(selectedEndDate);
    var diffDays = ""
    var feeVal = ""
    var rentalFee = ""
    var serviceFee = ""
    var total = ""
    const calcTotal = ()=>{
            feeVal = dayFee;
            const diffTime = Math.abs(date2 - date1);
            diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            rentalFee = feeVal * diffDays;
            serviceFee = rentalFee * .08;
            total = rentalFee + serviceFee;
    }
    const scheduleRental = () => {
        setProcessingSched(true)
        var schedTimeSecconds = new Date().getTime();
        if(addedTokenData){
            console.log(addedTokenData)
        }
        var ownPhone = rentalData.ownerPhone;
        if(ownPhone == undefined){
            ownPhone = "";
        }
        var data = {
            renter_start:formatDate(selectedStartDate),
            renter_end:formatDate(selectedEndDate),
            renter_pickup_time:pickupTime,
            custid:customerId,
            rental_id: rentalData.objectID,
            rentalType: rentalType.toLowerCase(),
            renter_name:user.displayName,
            renter_email:user.email,
            owner_email:rentalData.useremail,
            owner_name:rentalData.fname + ' ' + rentalData.lname,
            owner:rentalData.owner,
            owner_phone: ownPhone,
            renter_phone:userPhone,
            renter_uuid: user.uid,
            mainImage: rentalData.mainImage,
            schedTime: schedTimeSecconds,
            pMid:pmid,
            pMbrand:cardBrand,
            pMlast4:last4,
            preTaxCalc: taxAmount,
            afterTaxTotal: totalCharge,

        }
        var scheduleNew = firebase.functions().httpsCallable('scheduleNewRental');
        scheduleNew({scheduleData: data}).then((result) => {
                console.log(result);
                history.push('/schedulethankyou')

          }).catch((err) =>{
                alert(err)
                console.log(err)
                setProcessingSched(false)
          });	
    }

    switch (rentalType){
        case 'Day' : {  
            calcTotal();
            //setTotalCharge(total);
        break;
        }
    }
    var fee 
    return (
        <div style={{marginBottom:'100px'}}>

            <Container maxWidth="sm">
                <h1 className={classes.alignCent}>Complete Scheduling</h1>
                <h2 className={classes.alignCent}>Review and Pay</h2>
                <div>{rentalData.rentalTitle}</div>
                <div>
                    <div className={classes.horizCont}>
                        <div>${feeVal} x {diffDays} days</div>
                        <div>${formatMoney(rentalFee)}</div>
                    </div>
                    <div className={classes.horizCont}>
                        <div>Service Fee</div>
                        <div>${formatMoney(serviceFee)}</div>
                    </div>
                    {loadingTax ? 
                    <Spinner/> 
                    : 
                    <div>
                        <div className={classes.horizCont}>
                            <div>Tax</div>
                            <div>${formatMoney(taxAmount)}</div>
                        </div>
                        <div className={classes.horizContTotal}>
                            <div>Total</div>
                            <div>${formatMoney(totalCharge)}</div>
                        </div>
                    </div>
                    }

                </div>
                { processingSched ? 
                    <Spinner/>
                    :
                    <div className={classes.horizContButton} >
                            <Button class="btn btn--primary btn--medium" onClick={scheduleRental}>Reserve Rental</Button>
                    </div>
                }
                {loadingPayment ? 
                <Spinner/>
                :
                <div className={classes.alignCent}>
                    {hasSavedCard ?
                        <Container>
                            <div>Payment</div>
                            <div>{cardBrand}</div>
                            <div>{last4}</div>
                            <div className={classes.horizContButton} >
                                <Button class="btn btn--primary btn--medium" onClick={removeSavedCard}>Remove Saved Card</Button>
                            </div>
                        </Container>
                    :
                    <Container>
                        <div className={classes.marginSpace}><b>Add Saved Card</b></div>
                        <Elements stripe={stripePromise}>
                            <CardContainer/>
                        </Elements>
                    </Container>
                    }
                    <div>
                        <div className={classes.marginSpace}>We recommend contacting the owner to arrange a pickup time and location prior to finalizing rental.</div>
                        <div className={classes.marginSpace}>Cancelation or No Show</div>  
                        <div className={classes.marginSpace}>Cancel within 48 hours of booking and at least 24 hours before pickup and teh reservation is refundable. After that cancel up to 24 hours before pick up and the nights are refundable, but the service fee is not. Cancel within 24 hours of pick-up and the first night and serivce are non-refundable.</div>      
                        <div className={classes.marginSpace}>I agree to the Rules of Rentease, Cancellation Policy, and the Guest Refund Policy,. I also agree to pay the toal amount shown which includes service fees.</div>
                    </div>
                </div>
                }
            </Container>
        </div>
    )
}