import React, { useRef,useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import algoliasearch from 'algoliasearch';
import { ReactPhotoCollage } from "react-photo-collage";
import ShareIcon from '@material-ui/icons/Share';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StarIcon from '@material-ui/icons/Star';
import {firestore} from '../../../config'
import DefaultAlert from '../../DefaultAlert';
import { addDays } from 'date-fns'
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    dividerSpace: {
        margin:'10px 0px'
    },
    horizCont:{
        display:'flex',
        justifyContent:'space-around',
        margin:'10px 0px'
    },
    horizCont2:{
        display:'flex',
        justifyContent:'space-around',
        margin:'30px 0px'
    },
    horizTop:{
        display: 'flex',
        justifyContent: 'space-between'
    }
}));


export default function RentalDisplay(props) {
    const classes = useStyles();
    const history = useHistory();
	const user = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true)
    const [rentalDisData, setRentalDisData] = useState(null)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);    
    const [showMap, setShowMap] = useState(false)
    const [mapClass, setMapClass] = useState("")
    const [noResults, setNoResults] = useState(false)
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    var url_string = window.location.href;
    var url = new URL(url_string);
    var rid = url.searchParams.get("rid");
    


    let rentalData;
    
    function addToFavorites(){
        if(user){
            const cityRef = firestore.collection('favorites').doc(user.uid);
            const time = new Date().getTime()
            var obj = {};
            obj['favMap.'+rentalDisData.objectID] = time;
            const res = cityRef.update(obj);
            alert("Added to favorites.")
        }else{
            alert("Please login.")
        }
    }

    const ref = useRef(null);
    const [map, setMap] = useState();


    useEffect(() => {
        window.scrollTo(0, 0)
    },[]);
    useEffect(() => {
        console.log("SETUP CONDITION")
        console.log(props.location.state?.hit != undefined)
        if(props.location.state?.hit != undefined){//rid == null){
            console.log(props)
            setRentalDisData(props.location.state.hit)
            setIsLoading(false)
        }else{
            const searchClient = algoliasearch(
                process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
                process.env.REACT_APP_ALGOLIA_SEARCH_KEY
              );
              const index = searchClient.initIndex('rental_items');
              console.log("Getting item")
              var item = window.location.pathname.replace("/rental/","");
              console.log(item)

              index.search(item)
                    .then(({ hits }) => {
                        console.log(hits);
                        if(hits!= null && hits.length > 0 ){
                            setRentalDisData(hits[0])
                            setIsLoading(false)
                        }else{
                            setNoResults(true)
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
        }
    }, [rentalDisData])
    if(isLoading || rentalDisData == null){
        if(noResults){
            return <div style={{fontSize:'large',margin:'30px',textAlign:'center'}}>Item is not available.</div>
        }else{
             return <div>Loading</div>
        }
    }else{
    const ownerNumber = ""
    const ownerName = rentalDisData.fname + ' ' + rentalDisData.lname;
    const ownerEmail = rentalDisData.useremail
    const ownerUid = rentalDisData.owner
    const ownerPhoto = rentalDisData.ownerPhoto;
    const joinedDate = new Date(rentalDisData.ownerJoined*1000);
    const prefPickupT = rentalDisData.preferredpickup_start;
    const prefDroppoffT = rentalDisData.preferredpickup_end;
    // create date list 
    var unavalList = []
    if(rentalDisData.schedule_rentals != undefined){
        rentalDisData.schedule_rentals.forEach((sched)=>{
            console.log("---")
            console.log(sched.renter_end + " -- " + sched.renter_start)
            const diffTime = Math.abs(new Date(sched.renter_end) - new Date(sched.renter_start));
            var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            console.log(diffDays)
            for(var i = 0; i < diffDays; i ++){
                unavalList.push(addDays(new Date(sched.renter_start), i))
            }
            console.log(unavalList)
        })
    }
    var rentalReviews = 0;
    var rentalReviewsAvg = 0;
    if(rentalDisData.reviews != undefined && rentalDisData.reviews.length > 0 ){
        rentalDisData.reviews.forEach(element => {
            rentalReviewsAvg = rentalReviewsAvg + element.revNum
            });
            rentalReviewsAvg = rentalDisData.reviews.length
        rentalReviewsAvg = rentalReviewsAvg / rentalDisData.reviews.length
    }
    var desImageList = [{source:rentalDisData.mainImage}];
    const listItems = rentalDisData.additionalImages.map((number,index) =>{

        return (
        <div key={index} className="imageSetHeight">
            <img src={number} className="imageAutoHeight" />
        </div>)
        }

    );
    for(var i = 0; i< rentalDisData.additionalImages.length; i++){
        desImageList.push({source:rentalDisData.additionalImages[i]})
    }
    var settings = {}
    if(desImageList.length == 1){
        settings = {
            width: '100%',
            height: ['500px'],
            layout: [1],
            photos: desImageList,
            showNumOfRemainingPhotos: true
          };
    }else{
        settings = {
            width: '100%',
            height: ['500px', '200px'],
            layout: [1, 4],
            photos: desImageList,
            showNumOfRemainingPhotos: true
          };
    }


    function calculateDayDif(){
        if(startDate == null || endDate == null){
            return "0"
        }
        return getNumberOfDatesBetween(startDate,endDate)

    }
    function getNumberOfDatesBetween(date1, date2) {
        // Get the time in milliseconds for each date
        const time1 = date1.getTime();
        const time2 = date2.getTime();
      
        // Calculate the difference in milliseconds
        const diff = Math.abs(time1 - time2);
      
        // Convert the difference from milliseconds to days
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      
        // Return the number of days
        return days;
      }

    function convertDate(date){
        if(date == null){
            return "Select"
        }
        var d =  new Date(date)
        return d.getMonth()+1 + "/" + d.getDate() + "/" + d.getFullYear()
    }

    function calcTotalRen(){
        if(startDate == null || endDate == null){
            return ""
        }else{
            return rentalDisData.fee_d * getNumberOfDatesBetween(startDate,endDate)
        }
    }
    function getMonthYear(time){
        var date = new Date(time)
        const monthName = date.toLocaleString('default', {
            month: 'long',
          });

        return monthName + " " + date.getFullYear()

    }

    function sendToComplete(){
        if(startDate == null || endDate == null){
            setOpen(true)
        }else{
        history.push({
            pathname: '/completeschedule',
            state:{ sd:startDate,ed: endDate, pickupTime:rentalDisData.preferredpickup_start,dropOffTime:rentalDisData.preferredpickup_end, rdata:rentalDisData, rtype:'Day'}
          });
        }
    }
    var descT = rentalDisData.rentalDesc
    if(descT > 160){
        descT = descT.substring(0, 150);
    }

    return (
        <Wrapper apiKey={"AIzaSyBzkXrXbhh_Fi5iJE3PDq7DJvTcyvSy1UM"}>
        <div >
         <Container maxWidth="lg">
         <Helmet>
            <title>{rentalDisData.rentalTitle}</title>
            <link rel="canonical" href={window.location.href} />
            <meta name="description" content={descT} />
        </Helmet> 
        <article className="hit" itemScope itemType="http://schema.org/Product">
        {window.innerWidth > 650 ?
            <>
                 <div className={classes.horizTop} >
                 <div style={{display:'flex',justifyContent:'space-between',width:'100%',marginBottom:'15px'}}>
                    <div>
                        <h1 style={{fontSize:'xx-large',padding:'15px 0px'}}><b>{rentalDisData.rentalTitle}</b></h1>
                        <div style={{display:'flex'}}>
                            <div>${rentalDisData.fee_d} / Day</div>
                            <div className="bulletPoint">&bull;</div>
                            <div>{rentalDisData.pickupAddressCity}, {rentalDisData.pickupAddressState}</div>
                            <div className="bulletPoint">&bull;</div>
                            <div>Hosted By {rentalDisData.fname} {rentalDisData.lname} </div>
                        </div>
                        <div>
    
                        </div>
                    </div>
                    <div className="fb verticalMargin">
                        <div className="fb pad5" onClick={()=>{
                            var strin = window.location.href;
                            navigator.clipboard.writeText(strin).then(function() {
                              alert("Copied to clipboard")
                            }, function(err) {
                              console.error('Async: Could not copy text: ', err);
                            });
                        }}><ShareIcon/><div className="iconText">Share</div></div>
                        <div className="fb pad5" onClick={()=>{
                            addToFavorites()
                        }}><FavoriteIcon/><div className="iconText">Save</div></div>
                        <Link to={{ pathname: '/userdetails', query: {rentalPhoto:rentalDisData.mainImage, rentalTit: rentalDisData.rentalTitle,userNum: ownerNumber, userName: ownerName, userEmail:ownerEmail,userUid:  ownerUid , ownerPhoto: ownerPhoto} }}>
                            <Button size="small" variant="outlined" className="buttonBackground">Contact Owner</Button>
                        </Link>
                    </div>
                    </div>
                </div>
                <ReactPhotoCollage {...settings} />
                </>
         :
            <div>
            <Container maxWidth="sm">
            <Carousel>
                <div key='mainIm' className="imageSetHeight">
                    <img  src={rentalDisData.mainImage} className="imageAutoHeight" />
                </div>
                {
                    listItems
                }
            </Carousel>
            </Container>
                        <div className={classes.horizTop}>
                        <div>
                            <h2><b>{rentalDisData.rentalTitle}</b></h2>
                            <div>${rentalDisData.fee_d} / Day</div>
                        </div>
                        <div className="pad50">
                        <Link to={{ pathname: '/userdetails', query: {rentalPhoto:rentalDisData.mainImage, rentalTit: rentalDisData.rentalTitle,userNum: ownerNumber, userName: ownerName, userEmail:ownerEmail,userUid:  ownerUid , ownerPhoto: ownerPhoto} }}>
                            <Button size="small" variant="outlined" className="buttonBackground">Contact Owner</Button>
                        </Link>
                        <div className="fb pad5 jfr" 
                        onClick={()=>{
                            var strin = window.location.href;
                            navigator.clipboard.writeText(strin).then(function() {
                              alert("Copied to clipboard")
                            }, function(err) {
                              console.error('Async: Could not copy text: ', err);
                            });
                        }}><ShareIcon/><div className="iconText">Share</div></div>
                        <div className="fb pad5 jfr" onClick={()=>{
                            addToFavorites()
                        }}><FavoriteIcon/><div className="iconText">Save</div></div>
                    </div>
                    </div>
            </div>
        }
        </article>
            <br/>
            <div style={{fontSize:'large',fontWeight:'bold'}}>
                {rentalDisData.rentalDesc}
            </div>  
            <div className="contain2">
                <div style={{height:'100%'}} className="calContain">
                <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                        setOpen(false)
                        if(startDate == null){
                            setStartDate(date)
                        }else if(endDate == null){
                            setEndDate(date)
                        }else{
                            //reset
                            setStartDate(null)
                            setEndDate(null)
                        }
                    
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    inline="true"  
                    monthsShown={2}
                    excludeDates={unavalList}
                />

                </div>
                <div style={{padding:'10px',border:'1px solid grey',boxShadow: '5px 3px 3px grey' }} className="mw340">
                    <div className="fb sb">
                        <div style={{fontSize:'20px'}}>Availability</div>
                        <div className="fb ac"><StarIcon fontSize="10px"/><b>({rentalReviewsAvg}) {rentalReviews} Reviews</b></div>
                    </div>
                    <div style={{border:'1px solid grey'}}>
                        <div className="fb">
                            <div style={{width:'50%',border:'1px solid grey',padding:'8px'}}> 
                                <div style={{fontSize:'10px'}}>Pickup Date</div>
                                <div>{convertDate(startDate)}</div>
                            </div>
                            <div style={{border:'1px solid grey',width:'50%',padding:'8px'}}>
                                <div style={{fontSize:'10px'}}>Return Date</div>
                                <div>{convertDate(endDate)}</div>
                            </div>
                        </div>
                        <div style={{padding:'10px 8px',fontSize:'15px'}}>
                            Preferreed Pickup: {rentalDisData.preferredpickup_start}-{rentalDisData.preferredpickup_end}
                        </div>
                    </div>
                    <div style={{marginTop:'20px'}}>
                    <DefaultAlert text="Please complete scheduled data." toggleErrorState={open}/>
                        <Button size="small" variant="outlined" className="buttonBackground" style={{width:'100%'}}
                        onClick={() => {
                            sendToComplete()
                          }}
                        >Reserve</Button>
                    </div>
                    <div style={{textAlign:'center',margin:'10px 0px'}}>
                        You won't be charged yet
                    </div>
                    <div className="fb sb">
                        <div>{calculateDayDif()} x ${rentalDisData.fee_d} / Day</div>
                        <div>${calcTotalRen()}</div>
                    </div>
                </div>
            </div>
            <Divider className="hrd"/>
            <div className="fb sb">
                <div>
                    <div style={{fontSize:'large',fontWeight:'bold'}}>Pickup & Return Location</div>
                    <div>{rentalDisData.pickupAddressCity}, {rentalDisData.pickupAddressState}</div>
                </div>

                {!showMap ? 
                <div>
                    <Button size="small" variant="outlined" className="buttonBackground" 
                    onClick={()=>{
                        setShowMap(true)
                        setMapClass("mapHeight")
                            setMap(new window.google.maps.Map(ref.current, {
                                style:{height:'500px'},
                                center: { lat: rentalDisData._geoloc.lat, lng: rentalDisData._geoloc.lng},
                                zoom: 6,
                              }));
                        
                    }}
                >View Map</Button>
                </div>:<></>}
            </div>
            <div className="mapContainer">
            <div style={{marginBottom:"100px",background:'none !important'}}>
                <div ref={ref} className={mapClass}/>
            </div>
            </div>
            <div style={{marginBottom:'200px'}}>
            <div style={{fontSize:'large',fontWeight:'bold'}} className="ac fb"><StarIcon/>({rentalReviewsAvg}) {rentalReviews} Reviews</div>
                    <div className="contain2">
                        {rentalDisData.reviews?.map((number,index) =>{
                            return (
                                <div>
                                    <div className="fb">
                                        <div>
                                            <AccountCircleIcon fontSize="large"/>
                                        </div>
                                        <div style={{marginLeft:'20px',marginBottom:'20px'}}>
                                            <div style={{fontWeight:'bold'}}>{number.revName}</div>
                                            <div>{getMonthYear(number.revDate)}</div>
                                        </div>
                                    </div>
                                    <div style={{marginRight:'20px'}}>
                                    {number.revText}
                                    </div>
                                </div>
                                )
                        })
                        }
                    </div>
            </div>
         </Container>
        </div>
        </Wrapper>

    )
            }
}