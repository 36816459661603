import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { MdFingerprint } from 'react-icons/md'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Button } from './Button';
import './Navbar.css'
import { IconContext } from 'react-icons/lib'
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import {auth} from '../config'
import { useHistory } from "react-router-dom";

function Navbar(){
	const [click, setClick] = useState(false)
	const [button, setButton] = useState(true);
	const user = useContext(UserContext);
	const handleClick = () => setClick(!click)
	const closeMobileMenu = () => setClick(false)
	const history = useHistory()

	const showButton = () => {
		if(window.innerWidth <= 960){
			setButton(false)
		}else{
			setButton(true)
		}
	}
	const signOut = () => {
		console.log('signing off');
		auth.signOut()
		history.push('/');
	}
	window.addEventListener('resize',showButton);
	return(
	<>
	<IconContext.Provider value={{ color: '#565656'}}>
		<div className="navbar">
			<div className="navbar-container container colorMode">
				<Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
					<div><img src={window.location.origin+"/images/RentEase-Namestyle.svg"} width="220" height="50" style={{marginLeft:'-27px'}}/></div>
				</Link>
				<div className="menu-icon" onClick={handleClick}>
				{click ? <FaTimes /> : <FaBars />}
				</div>
				<ul className={click ? 'nav-menu active' : 'nav-menu'}>
					<li className="nav-item">
						<Link to='/' className="nav-links" onClick={closeMobileMenu}>
						Search
						</Link>
					</li>
					{user ? (
						<>
							<li className="nav-item">
								<Link to='/myaccount' userInfo={user} className="nav-links" onClick={closeMobileMenu}>
								My Account
								</Link>
							</li>
							<li className="nav-item">
								<Link to='/contacts' className="nav-links" onClick={closeMobileMenu}>
								Inbox
								</Link>
							</li>
							<li className="nav-item">
								<Link to='/addnewrental' className="nav-links" onClick={closeMobileMenu}>
								Add Rental
								</Link>
							</li>
						</>
							)
							 : (
								<li></li>
								)
							
					}
					<li className="nav-item">
						<Link to='/help'  className="nav-links" onClick={closeMobileMenu}>
						Help
						</Link>
					</li>
					<li className="nav-item">
						<Link to='/blog'  className="nav-links" onClick={closeMobileMenu}>
						Blog
						</Link>
					</li>
					<li className="nav-item">
						<Link to='/videos'  className="nav-links" onClick={closeMobileMenu}>
						Videos
						</Link>
					</li>
					<li className="nav-btn">
					{user ? (
							<Button onClick={signOut} buttonStype='btn--outline'>Sign Out</Button>
					) : (
						<Link to='/login' className="btn-link" onClick={closeMobileMenu}>
							<Button >Sign In</Button>
						</Link>
					)}
					</li>
				</ul>
			</div>
		</div>
	</IconContext.Provider>
	</>
	)
}

export default Navbar
	
	