import React, {useState , useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import { useHistory } from 'react-router-dom';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    background:'white'
  },
  iconButton: {
    padding: 10,
    color:'#326fa2;'
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();
  const history = useHistory();
  const [inputValue, setInputValue] = React.useState('')

  var searchTerm = ""
  const searchAction = (event) => {
    if (inputValue != "") {
      history.push('/search?searchTerm=' + inputValue)
    }
  };
  const enterAction = (event) => {
    if (event.key === 'Enter') {
      if (inputValue != "") {
        history.push('/search?searchTerm=' + inputValue)
      }
    }
  };
  const onChange = (event) => {
    searchTerm = event.target.value
    setInputValue(searchTerm);
  };
  const mapSearch = () =>{
    history.push('/search?view=map')
  }

  useEffect(() => {
    console.log(window.location.search)
    if (window.location.search.includes('?searchTerm=')) {
      const querystril = window.location.search.replace('?searchTerm=', '');
      setInputValue(querystril);
    }
	}, []);
  return (
    <Paper className={classes.root}>
      <InputBase type='text'
        className={classes.input}
        placeholder="Search Rentals"
        inputProps={{ 'aria-label': 'Search Rentals' }}
        onChange={onChange}
        onKeyDown={enterAction}
        value={inputValue}
      />
      <IconButton className={classes.iconButton} aria-label="search" onClick={searchAction}>
        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={mapSearch}>
        <LocationOnIcon />
      </IconButton>
    </Paper>
  );
}