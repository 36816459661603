import React, {useState , useEffect } from "react";
import { ListItemText, MenuItem, MenuList, Paper, ListItemIcon } from '@material-ui/core';
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { makeStyles } from '@material-ui/core/styles';
import {firestore} from '../../../config'
import Spinner from '../../Spinner'
import { useHistory } from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	headerTitle:{
		textAlign: 'center'
	},
	iconSize: {
		width: 50
	}
 }));
export default function MyFavorites(){
	const classes = useStyles();
	const user = useContext(UserContext);
	const history = useHistory()
	const [favData, setFavData] = React.useState([])
	const [queryComplete, setQueryComplete] = React.useState(false);

	useEffect(() => {
		if(user){
		const favRef = firestore.collection('favorites').doc(user.uid);
		const doc = favRef.get().then(doc => {
            if(doc.data().favMap){
                var favList = [];
                var promiseList = [];
                Object.keys(doc.data().favMap).forEach(function(key) {
                        var promise = firestore.collection('rental_items').doc(key).get().then( doc => {
                            favList.push(doc)
                        })
                        promiseList.push(promise);
                      })
                      Promise.all(promiseList).then((values) => {
                            setFavData(favList)
                            setQueryComplete(true)
                      });
            }else{
			setQueryComplete(true)
			}

		});
		}else{
			history.push('/myaccount');
		}
	}, []);
	if(queryComplete){
		return (
			<div>
				<Link to='/myaccount'>
                </Link>
				<h1 className={classes.headerTitle}>My Favorites</h1>
				<Paper>
					<MenuList>
						{favData.map(function(item,index){
							var hit = item.data();
							return (
                                <div>
									<MenuItem key={item.id} component={Link} to={{ pathname: '/rental', state: { hit } }}>
										<ListItemText primary={item.data().rentalTitle}/>
										<ListItemIcon>
											<img className={classes.iconSize} src={item.data().mainImage}/>
										</ListItemIcon>
									</MenuItem>
								<Divider/>
                                </div>
							)
						})}	
						{favData == [] || favData == 0 ? 
							<MenuItem>
									<ListItemText primary={<div style={{textAlign:'center'}}>No Favorites</div>}/>
								</MenuItem>
								:<div></div>
						}				
					</MenuList>
				</Paper>
			</div>
			)
		}else{
			return (<div>
				<Spinner/>
			</div>)
		}
}      
