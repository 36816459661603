import React, { useState } from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { Button, Container } from '@material-ui/core';
import firebase from 'firebase'
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";

export var addedTokenData = {};

export default function CardContainer() {
  const stripe = useStripe();
  const elements = useElements();
  const user = useContext(UserContext);
  const [addedCard, setAddedCard] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      console.log(result.error.message);
    } else {
      addedTokenData = result;
      stripeTokenHandler(result);
    }
  };
  function stripeTokenHandler(token) {
    console.log(token)
	var initStr = firebase.functions().httpsCallable('setCustomer');
	initStr({userData:{userid: '', useremail: user.email,paymentinfo:token,userSid:''}}).then((result) => {
			console.log(result);
            setAddedCard(true)
	  }).catch((err) =>{
			alert(err)
	  });	
  }
  return (
    <form>
      <CardElement/>
      {addedCard ?
        <Container>
            <div>
            Card Saved
            </div>
        </Container>
      :
      <Button variant="outlined" disabled={!stripe} style={{margin:'20px 0px'}} onClick={handleSubmit}>Save Card</Button>
        }
    </form>
  );
}