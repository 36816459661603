import React, { useState, useEffect, Fragment } from "react";
import Calendar from 'react-calendar';
import Container from '@material-ui/core/Container';
import 'react-calendar/dist/Calendar.css';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import {formatDate} from './DatePicker';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    dividerSpace: {
        margin: '10px 0px'
    },
    horizCont: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: '30px 0px'
    },
}));

export default function ScheduleRental(props) {
    const [selectedDate, onChange] = useState(null);
    const [infoDiaOpen, setDialogOpen] = useState(false);
    const classes = useStyles();
    const history = useHistory()
    function goBack() {
        history.goBack()
    }
    var selectedStartDate = props.location.state.sd;
    var selectedEndDate = props.location.state.ed;
    var rentalData = props.location.state.rdata;
    var prefPT = rentalData.preferredpickup_start
    var prefDT = rentalData.preferredpickup_end

    console.log("--", rentalData)
    useEffect(() => {
        console.log("useEffect")
        var selectData = new Array();
        if (selectedStartDate != null) {
            selectData.push(selectedStartDate)
            onChange(selectData)
        }
        if (selectedEndDate != null) {
            selectData.push(selectedEndDate)
            onChange(selectData)
        }
        setRentalType("Day")
    }, []);
    const selectDate = () => {
        console.log("selectDate")
        console.log(selectedDate)
        
        if(rentalType == "halfday" && selectedDate != null){
                selectedDate.splice(1, 1);
                onChange(selectedDate)
                alert("Please update rental type to day to request more then 1 day.")
                return
        }
        return

    }
    const getStart = () => {
        if (selectedDate[0] != null) {
            const date = new Date(selectedDate[0])
            selectedStartDate = date
            return formatDate(date)
        }
        return "Start Date"
    }
    const getEnd = () => {
        if (selectedDate[1] != null && selectedDate[1] != undefined) {
            const date = new Date(selectedDate[1])
            selectedEndDate = date
            return formatDate(date)
        } else {
            return "End Date"
        }
    }
    const [time, setTime] = useState('')
    const [dropTime, setDropTime] = useState('')
    const [dataComplete, setDataComplete] = useState(false)
    const [rentalType, setRentalType] = useState('Day')
    const handleChange = (event) => {
        setRentalType(event.target.value);
      };
    const dateAvailable = ({activeStartDate, date, view }) =>{
        if( rentalData.schedule_rentals != undefined){
            for(var i = 0 ; i < rentalData.schedule_rentals.length; i++){
                if(date >= new Date(rentalData.schedule_rentals[i].renter_start) && date <= new Date(rentalData.schedule_rentals[i].renter_end)){
                    return true;
                }
            }
        }else{
            return false;
        }
    }
    return (
        <div style={{marginBottom:'100px'}}>
            <Container maxWidth="sm">
                <br/>
                <h1 className="titleHeaderText" style={{padding:'0px'}}>Schedule Rental</h1>
                <div className={classes.horizCont}>
                <div>Select Type : </div>
                <FormControl className={classes.formControl}>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rentalType}
                    onChange={handleChange}
                    >
                    <MenuItem value='halfday'>Half Day</MenuItem>
                    <MenuItem selected={true} value='day'>Day</MenuItem>
                    <MenuItem value='week'>Week</MenuItem>
                    </Select>
                </FormControl>
                </div>
                <Calendar
                    allowPartialRange={true}
                    selectRange={true}
                    onChange={onChange}
                    value={selectedDate}
                    tileDisabled={dateAvailable}
                    onClickDay={selectDate}
                    className="react-cal-over"
                />
                <div>Owner prefers pickup and drop off time between:</div>
                <div>{prefPT} - {prefDT} <Button onClick={()=>{setDialogOpen(true)}}>?</Button></div>
                <Dialog
                    open={infoDiaOpen}
                    onClose={()=>{setDialogOpen(false)}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{"RentEase"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        This is the preferred pickup time and drop off time of the owner. Choosing a time within this range increases your likelihood of getting approved for this rental.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{setDialogOpen(false)}} color="primary" autoFocus>
                        Close
                        </Button>
                    </DialogActions>
                    </Dialog>
                <div className={classes.horizCont}>
                    <div>{selectedDate == null ? 'Start Date' : getStart()}</div>
                    <div>{selectedDate == null ? 'Start Date' : getEnd()}</div>
                </div>
                <Divider style={{margin:'10px 0px'}}/>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div>
                        <p>Pickup Time</p><br/>
                        <input type="time" onChange={event => {
                            setTime(event.target.value)    
                            if(time != '' && dropTime != '' && selectedDate != null ){
                            setDataComplete(true)
                            }
                        }}
                        />
                    </div>
                    <div>
                    <p>Drop Off Time</p><br/>
                    <input type="time" onChange={event => {

                        setDropTime(event.target.value)  
                        if(time != '' && selectedDate != null){
                        setDataComplete(true)
                        }
                    }}
                    />
                    </div>
                </div>
                <div className={classes.horizCont} >
                { dataComplete ?
                <Link to={{ pathname: '/completeschedule', state: { sd:selectedStartDate,ed: selectedEndDate, pickupTime:time,dropOffTime:dropTime, rdata:rentalData, rtype:rentalType} }}>
                    <Button variant="outlined">Continiue Scheduling</Button>
                </Link>
                :
                <Container>
                <div style={{textAlign:'center'}}>
                    Please Fill Out Information
                </div>
                </Container>
                }   
                </div>
            </Container>
        </div>
    );
}