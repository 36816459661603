import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    paper: {

    },
    control: {
        padding: theme.spacing(2),
    },
    title: {
        textAlign:'center',
        fontWeight: 'bold',
        marginBottom:'30px',
    },
    containClass:{
        justifyContent: 'center'
    }
}));
const helpDataRenter = [
    {
        topTitle: 'Updates On Rentals',
        bottomTitle: 'What you should know',
        key: 1
    },
    {
        topTitle: 'Cancelation Options',
        bottomTitle: 'Learn what\'s covered',
        key: 2
    },
    {
        topTitle: 'Help Center',
        bottomTitle: 'Get Support',
        key: 3
    },
    {
        topTitle: 'App Store',
        bottomTitle: 'Download App',
        key: 3
    },
];
const helpDataOwner = [
    {
      topTitle: 'For Owner',
      bottomTitle: '',
      key: 1
    },
    {
        topTitle: 'Updates On Rentals',
        bottomTitle: 'What you should know',
        key: 1
    },
    {
        topTitle: 'Cancelation Options',
        bottomTitle: 'Learn what\'s covered',
        key: 1
    },
    {
        topTitle: 'Help Center',
        bottomTitle: 'Get Support',
        key: 1
    },
];

export default function StayInformedPanel() {
    const classes = useStyles();
    const history = useHistory();

    return (
        <>
        <div className={classes.title}>Stay Informed</div>
        <div>
            <Grid item style={{display:'flex',justifyContent:'space-around'}}>
                <Grid container className={classes.containClass}>
                    {helpDataRenter.map((value) => {

                        if(value.topTitle == 'App Store'){
                            return (
                                <Grid key={value.key} item>
                                    <Paper className="stayInformedContainer darkBorder" onClick={() =>{history.push("/apps")}}>
                                        <div style={{height:'100%'}}>
                                            <div style={{paddingTop:'3px'}}><b style={{fontSize:'12px'}}>{value.topTitle}</b></div>
                                            <div style={{fontSize:'12px'}}>{value.bottomTitle}</div>
                                        </div>
                                    </Paper>
                                </Grid>
                                )
                        }else{
                            return (
                            <Grid key={value.key} item>
                                <Paper className="stayInformedContainer darkBorder" onClick={() =>{history.push("/help")}}>
                                    <div style={{height:'100%'}}>
                                        <div style={{paddingTop:'3px'}}><b style={{fontSize:'12px'}}>{value.topTitle}</b></div>
                                        <div style={{fontSize:'12px'}}>{value.bottomTitle}</div>
                                    </div>
                                </Paper>
                            </Grid>
                            )
                        }
                    })}
                </Grid>
            </Grid>
        </div>
        </>
    )

}