import React, { useState,useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import Spinner from '../../Spinner'
import { useHistory } from "react-router-dom";
import { auth,firestore } from '../../../config'
import { FilePicker } from 'react-file-picker'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import firebase from 'firebase'


const useStyles = makeStyles((theme) => ({
	headerTitle: {
		textAlign: 'center'
	},
	iconSize: {
		width: 30,
		height: 30
	}
}));



export default function TaxPage() {
	const classes = useStyles();
	const user = useContext(UserContext);
	const history = useHistory()
  const [hasCustomer, setHasCustomer] = useState(false)
  const [hasAddress, setHasAddress] = useState(false)
  const [hasPDF, setHasPDF] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
	//console.log(user);
	const logOff = () => {
		console.log('signing off');
		auth.signOut()
		history.push('/');
	}

    const createTaxCustomer = () =>{
      console.log("Create")
      var createObject = { userUid:userUid, userName:userDisplayName}
      console.log(createObject)
      var scheduleNew = firebase.functions().httpsCallable('createTaxCustomer');
      scheduleNew(createObject).then((result) => {
        console.log(result)

        }).catch((err) =>{
          console.log(err)

        });

    }
    const openTaxAddress = () =>{
      console.log("UPDATE")
      if (displayAddrFields){
        setDisplayAddrField(false)
      }else{
        setDisplayAddrField(true)
      }
    }
    const updateTaxAddr = () =>{
      console.log("updating addr")
      var addrObj = {addr1:addr1,addr2:addr2,city:city,state:state,zip:zip,avalaraCustomerId:avalaraCustomerId}
      console.log(addrObj)
      var scheduleNew = firebase.functions().httpsCallable('updateTaxAddress');
      scheduleNew(addrObj).then((result) => {             
        console.log(result)

        }).catch((err) =>{
          console.log(err)

        });
      setDisplayAddrField(false)
    }

    
    const closeAddrForm = () =>{
      setDisplayAddrField(false)
    }

    const updatePDFData = (pdfString) =>{
      var PDFdata = {pdfString:pdfString,exposureZone:state}
      console.log(PDFdata)
      var scheduleNew = firebase.functions().httpsCallable('uploadTaxPDF');
      scheduleNew(PDFdata).then((result) => {
        console.log(result)
        }).catch((err) =>{
          console.log(err)

        });
    }

    const fileToBase64 = (file, cb) => {
        console.log(file)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          updatePDFData(reader.result)
        }
        reader.onerror = function (error) {
          cb(error, null)
        }
    }

const fetchAvalaraCustomer = () => {
  console.log("FETCHING")
  if(user.uid != undefined ){
    var scheduleNew = firebase.functions().httpsCallable('fetchTaxCustomer');
    scheduleNew().then((result) => {
      var jsonResponseData = JSON.parse(result.data).data
      console.log(result.data)
      if(jsonResponseData.length > 0){
        setAvalaraCustomerId(jsonResponseData[0].id)
        setHasCustomer(true)
        setIsLoading(false)
        if(jsonResponseData[0].address_line1 != undefined && jsonResponseData[0].address_line1 != ""){
          setAddr1(jsonResponseData[0].address_line1)
          setAddr2(jsonResponseData[0].address_line2)
          setCity(jsonResponseData[0].city)
          setZip(jsonResponseData[0].zip)
          setState(jsonResponseData[0].state.name)
        }


      }else{
        setHasCustomer(false)
        setIsLoading(false)
      }
      }).catch((err) =>{
        console.log(err)

      });
  }
}

	useEffect(() => {
		if (user) {
      setUserUid(user.uid)
      setUserDisplayName(user.displayName)
      fetchAvalaraCustomer()
		} else{
            history.push('/login')
        }
	}, []);


  const [addr1,setAddr1] = useState("")
  const [addr2,setAddr2] = useState("")
  const [city,setCity] = useState("")
  const [state,setState] = useState("")
  const [zip,setZip] = useState("")
  const [userUid, setUserUid] = useState("")
  const [avalaraCustomerId, setAvalaraCustomerId] = useState("")
  const [userDisplayName, setUserDisplayName] = useState("")
  const [displayAddrFields, setDisplayAddrField] = useState(false)

  console.log("has customer : " + hasCustomer)
	if (user && !isLoading) {
		return (
			<div style={{marginBottom:'150px'}}>
				<h1 className="titleHeaderText" >Tax Information</h1>
				<div>{user.displayName}</div><br />
				<div>{user.email}</div>
              {hasCustomer ? 
              <div><div>Customer Number : {user.uid}</div> <br/></div>
                :
                <div style={{margin:'10px 0px'}}><b>Please create customer.</b></div>
              }
              {hasAddress ? 
                <div>AddressUpdated </div>: 
                <></>
              }
              {hasPDF ? 
                <div>PDF Uploaded</div>: 
                <></>
              }
				<br />

				<Paper>
					<MenuList>
            {hasCustomer ? <></> : 
						<MenuItem component={Link} onClick={()=>{createTaxCustomer()}} >
							<ListItemText primary="Create Tax Customer" />
							<ListItemIcon>
								<img className={classes.iconSize} src='images/Renter.png' />
							</ListItemIcon>
						</MenuItem>
            }
            {hasCustomer ? <>
            <MenuItem component={Link} onClick={()=>{openTaxAddress()}}>
							<ListItemText primary="Update Tax Address" />
							<ListItemIcon>
								<img className={classes.iconSize} src='images/Renter.png' />
							</ListItemIcon>
                </MenuItem>
                <MenuItem>
                {displayAddrFields ?
                <div style={{padding:'30px',width:'100%'}}>
                  <div>Address Information</div>
                  <div>
                  <TextField
                      label="Address Line 1"
                      placeholder="Enter Address"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                  value={addr1}
                  onChange={e => setAddr1(e.target.value)}
                    /><br/>
                    <TextField
                      label="Address Line 2"
                      placeholder="Enter Address"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                  value={addr2}
                  onChange={e => setAddr2(e.target.value)}
                    /><br/>
                    <TextField
                      label="City"
                      placeholder="Enter City"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                    /><br/>
                    <TextField
                      label="State"
                      placeholder="Enter State Long Name"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                  value={state}
                  onChange={e => setState(e.target.value)}
                    /><br/>
                    <TextField
                      label="Zipcode"
                      placeholder="Enter Zipcode"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                  value={zip}
                  onChange={e => setZip(e.target.value)}
                    /><br/>
                  </div>
                  <Button varient="outlined" onClick={()=>{updateTaxAddr()}}>Update Address</Button>
                  <Button varient="outlined" onClick={()=>{closeAddrForm()}}>Close</Button>

                </div>
                : 
                <>...</>}
                </MenuItem>
                <div style={{padding:'0 8px'}}>
                  <FilePicker extensions={['pdf']}
                      onChange={file => (fileToBase64(file,(res,res2)=>{console.log(res2)}))}
                      onError={errMsg => (/* do something with err msg string */alert(errMsg))}>
                      <Button>
                      Click to upload PDF
                      </Button>
                  </FilePicker>
                </div>
                </>
                : <></>}
					</MenuList>
				</Paper>
			</div>
		);
	} else {
		return (
			<div>
				<Spinner />
			</div>
		)
	}
}