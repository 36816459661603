import { Button, Container } from '@material-ui/core';
import React, {useState , useEffect } from "react";
import Calendar from 'react-calendar';
import {makeStyles} from '@material-ui/core'
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import firebase from 'firebase'
import { Link, useHistory } from "react-router-dom";
import Spinner from '../../Spinner'

const useStyle = makeStyles((theme) => ({
    buttonMarg: {
        margin:'20px 0px'
    },
    calendarWidth:{
        width:'auto'
    }
}));

export default function ScheduledRentalDisplay(props){
    const classes = useStyle();
    const schedData = props.location.state.schedData
    const schedId = props.location.state.schedId
    const userType = props.location.state.userType
    const user = useContext(UserContext);
    const [noButtonNeeded, setNoButtonNeeded] = useState(false)
    const [actionButtonTitle, setActionButtonTitle] = useState('')
    const [actionId, setActionId] = useState('')
    const [processingSched, setProcessingSched] = useState(false);
    const history = useHistory()
    console.log(schedData)
    console.log(schedId)
    if(user == undefined){
        history.push("/");
    }

    const mainImg = schedData.rentalDetails.mainImage
    const addressInfo = schedData.pickupAddress;
    const rentalTitle = schedData.rentalDetails.rentalTitle
    const renterS = new Date(schedData.renterData.renter_start)
    const renterE = new Date(schedData.renterData.renter_end)
    const isOwner = user.uid == schedData.renterData.owner
    const pickupTime = schedData.renterData.renter_pickup_time
    const dropoffTime = schedData.renterData.renter_dropoff_time
    const rName = schedData.renterData.renter_name
    const oName = schedData.renterData.owner_name
    const statusS = schedData.renterData.status

    const schedDetailData = {
        schedule_id:schedId,
        title : rentalTitle,
        ownerN : schedData.renterData.owner_name,
        renterN : schedData.renterData.renter_name,
        pickupTime : pickupTime,
        dropoffTime : dropoffTime,
        pickupLocation : addressInfo.pickupAddress1 +' '+ addressInfo.pickupAddressCity + ', ' + addressInfo.pickupAddressState,
        dropoffLocation : addressInfo.dropoffAddress1 +' '+ addressInfo.dropoffAddressCity + ', ' + addressInfo.dropoffAddressState,
        pickupDate: schedData.renterData.renter_start,
        userType:userType,
    }
    
    //button values
    useEffect(() => {
        if(isOwner){
            switch(statusS){
                case 'Pending': 
                setNoButtonNeeded(false);
                break;
                case 'Approved' : 
                setActionButtonTitle("Owner Action")
                setActionId("003")
                break
                case '007' : 
                setActionButtonTitle("Owner Action")
                setActionId("007")
                break
                default :
                setNoButtonNeeded(true);
                break
            }
            if(schedData.renterData.owner == schedData.renterData.renter_uuid){
                switch(statusS){
                    case '004' : 
                    setNoButtonNeeded(false);
                    setActionButtonTitle("Renter Action")
                    setActionId("004")
                    break
                    case '006' : 
                    setNoButtonNeeded(false);
                    setActionButtonTitle("Renter Action")
                    setActionId("006")
                    break
                }
            }
        }else{
            switch(statusS){
                case '004' : 
                setActionButtonTitle("Renter Action")
                setActionId("004")
                break
                case '006' : 
                setActionButtonTitle("Renter Action")
                setActionId("006")
                break
                default :
                setNoButtonNeeded(true);
                break
            }
            if(schedData.renterData.owner == schedData.renterData.renter_uuid){
                switch(statusS){
                    case 'Pending': 
                    setNoButtonNeeded(false);
                    break;
                    case 'Approved' : 
                    setActionButtonTitle("Owner Action")
                    setActionId("003")
                    break
                    case '007' : 
                    setActionButtonTitle("Owner Action")
                    setActionId("007")
                    break
                }
            }
        }
    });
    const approveRental = () =>{
        setProcessingSched(true);
        var scheduleNew = firebase.functions().httpsCallable('approveRental');
        scheduleNew({schedid: schedId,rentData: schedData }).then((result) => {
                console.log(result);
                console.log("Rental Approved.")
                history.goBack();
          }).catch((err) =>{
                alert(err)
                setProcessingSched(false);
          });
    }
    const denyRental = () => {
        setProcessingSched(true);
        var scheduleNew = firebase.functions().httpsCallable('deleteScheduleRental');
        scheduleNew({schedid: schedId,rentData: schedData }).then((result) => {
                console.log(result);
                history.goBack();
          }).catch((err) =>{
                alert(err)
                setProcessingSched(false);
          });
    }

    function goBack() {
        history.goBack()
    }
    return(
        <Container maxWidth="md">
        <div style={{marginBottom:'100px'}}>

                <br/><br/>
            <Container>
            <h1>Scheduled Rental</h1>
            <div>{rentalTitle}</div>
            <p>Start: {schedData.renterData.renter_start}</p>
            <p>End: {schedData.renterData.renter_end}</p>
            <p>Pickup Time : {pickupTime}</p>
            <div>Status</div>
            <h2>Pending</h2>
            </Container>
            {isOwner ? 
                <Container>
                    <div>Owner: {oName}</div>
                </Container>
            :
                <Container>
                    <div>Renter: {rName}</div>
                </Container>
            }
            <br/>
            <Container maxWidth="md">
            <img src={mainImg}/>
            <div style={{pointerEvents:'none'}}>
            <Calendar
                    className={classes.calendarWidth}
                    allowPartialRange={true}
                    defaultValue={[renterS,renterE]}
                    
                />
            </div>
            </Container>
            {noButtonNeeded || processingSched ? 
            <Container style={{height:'40px',padding:'20px'}}>{processingSched ? <Spinner/> : <></>}</Container>
            :
            <Container>
                {statusS == "Pending" ?
                <div style={{textAlign:'center'}}>
                    <div className={classes.buttonMarg}>
                        <Button onClick={approveRental} variant="outlined" size="large">Approve Renter</Button>
                    </div>
                    <div className={classes.buttonMarg}>
                        <Button onClick={denyRental} variant="outlined">Deny Renter</Button>
                    </div>
                </div>
                :
                <Container>
                     <div style={{textAlign:'center'}} className={classes.buttonMarg}>
                        <Link to={{ pathname: '/scheduleaction', state: { schedData:schedId, stepId: actionId, detailData:schedDetailData } }}>
                        <Button className="buttonBackground" variant="outlined" size="large">{actionButtonTitle}</Button>
                        </Link>
                    </div>
                </Container>
                }
            </Container>
            }
            {isOwner ? 
            <div style={{textAlign:'center'}}>
                <div>
                <Link to={{ pathname: '/contacts', query: { toUserUid:schedData.renterData.renter_uuid,toUserPhoto: schedData.renterData.renterPhoto,toUserEmail: schedData.renterData.renter_email,toUsername: schedData.renterData.renter_name, rname:rentalTitle , rimage: mainImg} }}>
                    <Button className="buttonBackground" variant="outlined">Message Renter</Button><br/><br/>
                </Link>
                <Link to={{ pathname: '/transactiondetails', query: {schedIdent:schedId, scheduleDat:schedData} }}>
                    <Button className="buttonBackground" variant="outlined">Transaction Details</Button><br/><br/>
                </Link>
                </div>
                <div>
                    <Button className="buttonBackground" variant="outlined">Email Renter</Button>
                </div>
            </div>
            :
            <div style={{display:'flex'}}>
                <div>
                <Link to={{ pathname: '/contacts', query: { toUserUid:schedData.renterData.owner,toUserPhoto: schedData.renterData.ownerPhoto,toUserEmail: schedData.renterData.owner_email,toUsername: schedData.renterData.owner_name, rname:rentalTitle , rimage: mainImg} }}>
                    <Button className="buttonBackground" variant="outlined">Message Owner</Button><br/><br/>
                </Link>
                <Link to={{ pathname: '/transactiondetails', query: {schedIdent:schedId, scheduleDat:schedData} }}>
                    <Button className="buttonBackground" variant="outlined">Transaction Details</Button><br/><br/>
                </Link>
                </div>
                <div>
                    <Button className="buttonBackground" variant="outlined">Email Owner</Button>
                </div>
            </div>
            }
        </div>
        </Container>
    )

}