import React from 'react'
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

export default function AddRentalSentToApp() {
    const history = useHistory();

    const openAppStore = () =>{
        history.push("/apps");

    }

    return (
        <Container maxWidth="md">
            <div style={{textAlign:'center',margin:'30px 0px'}}>
                <Container maxWidth="md">
                    <h2 style={{marginBottom:'30px'}}>Thank you for your interest in adding a rental to RentEase. Currently we only allow owners to add rentals using Mobile Device Applications. Please visit the App Store if you would like to list an item.</h2>
                </Container>
                <Button variant="outlined" onClick={openAppStore} className="buttonBackground">Download RentEase App</Button>
            </div>
        </Container>
    )
}