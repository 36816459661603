import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import Spinner from '../../Spinner'
import Button from '@material-ui/core/Button';
import firebase from 'firebase'
import {firestore} from '../../../config'
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
	headerTitle:{
		textAlign: 'center'
	},
	iconSize: {
		width: 30,
		height:30
	}
 }));


var userStripeAccount = ""
export default function MyPayments() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [hasStripe, setHasStripe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const initStripe = (user) => {
	setIsLoading(true)
	//firebase.functions().useEmulator("localhost", 5002);
	var initStr = firebase.functions().httpsCallable('createStripeAccountLink');
	initStr().then((result) => {
			console.log(result);
			window.open(result.data.url)
			setIsLoading(false)
	  }).catch((err) =>{
			alert(err)
	  });	
};
const goToStrip = (user) => {
	setIsLoading(true)
	//firebase.functions().useEmulator("localhost", 5002);
	var initStr = firebase.functions().httpsCallable('getStripeAccountLink');
	initStr({ stripeAcc: userStripeAccount }).then((result) => {
			console.log(result);
			window.open(result.data.url)
			setIsLoading(false)
	  }).catch((err) =>{
			alert(err)
	  });	
};
  useEffect(() => {
	if(user){
	const schedRef = firestore.collection('users').doc(user.uid);
	const doc = schedRef.get().then(doc => {
		console.log(doc.data())
		if( doc.data().stripedata != undefined && doc.data().stripedata.stripe_account_id != undefined){
			userStripeAccount = doc.data().stripedata.stripe_account_id;
			setHasStripe(true);
		}
		setIsLoading(false)
	});
	}else{
		history.push('/login')
	}
	}, []);

  if(user && !isLoading ){
  return (
    <div>
	<Link to='/myaccount'>
                </Link>
	  <h1 className={classes.headerTitle}>My Payments</h1>
	  <br/>
      <Paper>
        <MenuList>
			{ !hasStripe ?	
				<MenuItem onClick={initStripe}>
					<ListItemText primary="Create Stripe Account" />
					<ListItemIcon>
						<img className={classes.iconSize} src='images/Renter.png'/>
					</ListItemIcon>
		  		</MenuItem>
			:
				<MenuItem onClick={goToStrip}>
					<ListItemText primary="Go to Stripe Acccount" />
					<ListItemIcon>
						<img className={classes.iconSize} src='images/Renter.png'/>
					</ListItemIcon>
				</MenuItem>
 			 }
		  <Divider />
          <MenuItem component={Link} to="/savedpayments">
			<ListItemText primary="Saved Payments" />
			<ListItemIcon>
				<img className={classes.iconSize} src='images/Owner.png'/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
		  <MenuItem component={Link} to="/taxinfo" >
			<ListItemText primary="Tax Info" />
			<ListItemIcon>
				<img className={classes.iconSize} src='images/Owner.png'/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
        </MenuList>
      </Paper>
    </div>
  );
  }else{
		return (
			<div>
				<h1 className={classes.headerTitle}>My Payments</h1>
				<Spinner/>
			</div>
		)
  }
}