import React, { useState } from "react";
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { signInWithGoogle, signUpWithEmail } from './config'
import { useContext } from "react";
import { UserContext } from "./providers/UserProvider";
import { useHistory } from "react-router-dom";
import Spinner from './components/Spinner'
import firebase from 'firebase'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center'
  },
  textField: {
  },
  centerF: {
    textAlign: 'center'
  },
  googleButt: {
    height: '36px'
  },
  googleImg: {
    height: '26px',
    margin: '0px 8px'
  }
}));

const SignUp = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVerify, setPasswordVerify] = useState('');
  const [error, setError] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordVerify, setShowPasswordVerify] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowPasswordVerify = () => setShowPasswordVerify(!showPasswordVerify);


  const user = useContext(UserContext);
  const history = useHistory()
  const addNewByEmail = (event) => {
    console.log(password)
    console.log(passwordVerify)

    if(password != passwordVerify){
      alert("Password does not match.")
      return;
    }
      let newdata = signUpWithEmail(event, email, password).then((res) =>{
        //history.push('/newuseradd');
        const user = firebase.auth().currentUser;

        user.updateProfile({
          displayName: displayName,
        }).then(() => {
          // Update successful
          // ...
          history.push("/myaccount")
        }).catch((error) => {
          // An error occurred
          // ...
          console.log(error)
          history.push("/myaccount")

        }); 
      }).catch((err)=>{
        alert(err.message)
      })
  }

  const emailVal = (val) => {
    setEmail(val.target.value);
  }
  const passVal = (val) => {
    setPassword(val.target.value)
  }

  return (
    <div style={{marginBottom:'200px'}}>
    <div className={classes.root}>

    <Container maxWidth="sm" style={{border:'1px solid black',margin:'20px',borderRadius:'15px',padding:'10px'}}>
        <h1 style={{textAlign:'center'}}>Create New Account</h1>
        <br/><br/>
        <div >
          {error !== null && <div>{error}</div>}
          <Button className={classes.googleButt} onClick={signInWithGoogle} variant="outlined"><img alt="google login" className={classes.googleImg} src="/images/google-icon-logo-svg-vector.svg" />Create With Google</Button>
          <br/>
          <br/>
          <div>
          <h2 style={{textAlign:'center'}}>Or</h2>
          </div>

          <form className="">
            <TextField
              id="outlined-full-width"
              label="Name"
              placeholder="Enter name here"
              helperText="First and last name."
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={displayName}
              onChange={(e)=>{setDisplayName(e.target.value)}}
            />
            <TextField
              id="outlined-full-width"
              label="User Email"
              placeholder="Enter email here"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={email}
              onChange={emailVal}
            />
            <TextField
              id="outlined-full-width"
              label="User Password"
              placeholder="Enter password here"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              type="password"
              variant="outlined"
              value={password}
              onChange={passVal}
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              //onChange={someChangeHandler}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              id="outlined-full-width"
              label="Verify Password"
              placeholder="Verify password here"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              type="password"
              variant="outlined"
              value={passwordVerify}
              onChange={(e)=>{setPasswordVerify(e.target.value)}}
              type={showPasswordVerify ? "text" : "password"} // <-- This is where the magic happens
              //onChange={someChangeHandler}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordVerify}
                      //onMouseDown={handleMouseDownPassword}
                    >
                      {showPasswordVerify ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button onClick={addNewByEmail} variant="outlined">Create</Button>
          </form>
        </div>
      </Container>
    </div>
    </div>
  );

};
export default SignUp;