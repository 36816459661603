import React from 'react'
import { Button, Divider } from '@material-ui/core'
import { Link } from 'react-router-dom';


export default function ScheduleThankYou(){
    
    return(
        <div style={{textAlign:'center',margin:'50px 0px'}}>
            <h1 style={{textAlign:'center'}}>Thank you for choosing RentEase!</h1>
            <Divider style={{margin:'20px 0px'}}/>
            <h2 style={{textAlign:'center'}}>Your Scheduled Rental has been sent to owner for approval.</h2>
            <Divider style={{margin:'20px 0px'}}/>
            <Link to="/myaccount">
                <Button size="small" variant="outlined" className="buttonBackground" >Go to My Account</Button>
            </Link>
            <Divider style={{marginBottom:'100px'}}/>

        </div>
    )
}