import React, {useState} from "react";
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router';

const AppConnect = () => {
    const history = useHistory();


    function learnMoreLink(){
        console.log("learnmorelcick")
        history.push("/")
    }

    function openAppleStore(){
        window.open("https://apps.apple.com/app/id1621378785", "_blank")
    }

    function openAndroidStore(){
      window.open("https://play.google.com/store/apps/details?id=com.renteasereactnative1.production&hl=en_US&gl=US", "_blank")
    }

  return(


    <div style={{display:'flex',justifyContent:'center'}}>
      <Container maxWidth="sm" style={{border:'1px solid black',margin:'40px 20px',borderRadius:'15px',padding:'10px',display:'flex',justifyContent:'center',marginTop:'50px',flexDirection:'column',padding:'50px 0px'}}>
        
        <div style={{margin:'auto'}}> 
          <img style={{borderRadius:'15px'}} src="images/RentEase-App-Icons5.jpg" width="100" height="100"/>
        </div>
          <div style={{fontSize:'30px',textAlign:'center',margin:'20px 0px'}}>Your New Rental Service</div>
          <div onClick={learnMoreLink} style={{border:'1px solid black',margin:'20px',borderRadius:'15px',width:'100px',textAlign:'center',margin:'auto',marginBottom:'20px',cursor:'pointer'}}>Learn more</div>
          <div style={{margin:'auto',cursor:'pointer'}} onClick={openAppleStore}> 
          <img src="images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" width="100" height="50"/>
        </div>
        <div style={{margin:'auto',cursor:'pointer'}} onClick={openAndroidStore}> 
          <img src="images/google-play-badge.png" width="118" height="50"/>
        </div>
      </Container>
    </div>
  )
};
export default AppConnect;