import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import CustomizedInputBase from '../../SearchBar'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '60px',
    marginTop: '15px'
  },
  title: {
    fontSize: '16px',
    whiteSpace:'normal',
    background:'transparent',
    textAlign:'center',
    fontWeight:'bold',
    color:'white'
  },

}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export const tileData = [ 
  {
    img: 'images/RentEase-Mark.svg',
    title: 'Everything!',
    key: 122
  },
  {
    img: 'images/Construction-Equipment.jpg',
    title: 'Construction Equipment',
    key: 1
  },
  {
    img: 'images/Ag-Equipment.jpg',
    title: 'Ag. Equipment',
    key: 2
  },
  {
    img: 'images/Sporting-Goods.jpg',
    title: 'Sporting Goods',
    key: 3
  },
  {
    img: 'images/Tools.jpg',
    title: 'Tools',
    key: 4
  },
  {
    img: 'images/Trailers-Campers-2.jpg',
    title: 'Trailers / Campers',
    key: 5
  },
  {
    img: 'images/Party-Goods-Weddings-2.jpg',
    title: 'Party Goods / Weddings',
    key: 6
  },
  {
    img: 'images/Clothing-2.jpg',
    title: 'Clothing, Shoes, Accessories',
    key: 7
  },
  {
    img: 'images/Electronics-2.jpg',
    title: 'Electronics',
    key: 8
  },
  {
    img: 'images/Baby-goods-2.jpg',
    title: 'Baby Goods',
    key: 9
  },
  {
    img: 'images/Home-&-Garden.jpg',
    title: 'Home and Garden',
    key: 10
  },
  {
    img: 'images/Musical-Instruments.jpg',
    title: 'Musical Instruments',
    key: 11
  },
  {
    img: 'images/Photography.jpg',
    title: 'Photography',
    key: 12
  },
  {
    img: 'images/Luggage.jpg',
    title: 'Luggage',
    key: 13
  },
  {
    img: 'images/Everything-Else.jpg',
    title: 'Everything Else',
    key: 14
  }
];

export default function TopCats() {
  const classes = useStyles();
  const history = useHistory();
  const [screenSize, setScreenSize] = useState(window.innerWidth)

  function formatCategoryURL(category){
    return category.replaceAll(" ", "-").replaceAll("/", "")
  } 
  

  const clickImage = (val) => {
    history.push({
      pathname: '/category/'+formatCategoryURL(val),
      state: { catName: val }
    })
  }
  const viewAll = () => {
    history.push('/topcategories')
  }

  function resizeFun() {
    setScreenSize(window.innerWidth);
  }

  window.onresize = resizeFun

    useEffect(() => {
      window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.root}>
    <div className="bluePageBack">
				<div style={{paddingTop:'50px',fontWeight:'bolder',fontWeight:'36px',fontWeight:'bolder',fontSize:'xxx-large',background:'transparent',color:'white'}}>Browse Categories.</div>
				<div style={{fontSize:'small',fontWeight:'bold',maxWidth:'600px',paddingTop:'20px',margin:'auto',background:'transparent',color:'white'}}>With RentEase, lease anything from vehicles to equipment to kayaks with just a few easy clicks.
We’ll be your rental business so you can rent with ease.</div>
			</div>
			<div style={{padding:'20px',background:'white',borderRadius:'20px',marginTop:'-50px',marginLeft:'auto',marginRight:'auto',maxWidth:'800px',boxShadow:'0 3px 10px rgb(0 0 0 / 0.2)',marginBottom:'30px'}}>
				<CustomizedInputBase />
			</div>

      <GridList className={classes.gridList} cols={screenSize < 600 ? 2 : 4}>
        {tileData.map((tile) => (
          <GridListTile key={'upper'+tile.key} className="catCatSlist" >
          <img className="hoverImage" src={tile.img} alt={tile.title} onClick={() => clickImage(tile.title)} style={{width:'100%',height:'100%'}}/>
          <GridListTileBar
            title={tile.title}
            classes={{
              root: classes.titleBar,
              title: classes.title,
            }}
          />
        </GridListTile>
        ))}
      </GridList>
    </div>
  );
}