import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import DocumentMeta from 'react-document-meta';

const useStyles = makeStyles((theme) => ({
	headerTitle:{
		textAlign: 'center'
	},
	iconSize: {
		width: 30
	},
	root: {
		width: '100%',
		background:'transparent',
		border: '1px solid grey',
		marginBottom:'20px',
		borderRadius:'12px',
		boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
	  },
	  heading: {
		fontSize: 'large',
		fontWeight:'bold'
	  },
	accordRoot:{
		background:'transparent'
	},
	accordSum:{
		background: 'black',
		borderRadius: '15px',
	},
	lb:{
		whiteSpace: 'pre-line'
	}
 }));

export default function BlogTop() {
    const meta = {
        title: 'RentEase Blog',
        description: 'View the latest news on RentEase',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'renting,saving,money,equipment,cheap'
            }
        }
    }
    
  const classes = useStyles();
  const history = useHistory();
  function transformTitle(title){
    return title.replaceAll(" ", "-")
  }

  var blogMap = [{blogTitle:'Renting personal property like lawn mowers, cars, tools, and boats has its own advantages over buying.',
                  blogDate:'March 20, 2023',
                  blogTop3:'Renting personal property like lawn mowers, cars, tools, and boats has its own advantages over buying. Here are five reasons why you should consider renting instead of buying these items:'}]
  return (
    <div className="blogBody">
        <DocumentMeta {...meta} />
        <h1 style={{fontSize:'30px'}}>RentEase Blog</h1>
        <div>This blog is the official source for the updates from the RentEase team. Anything important, including rental notes or new feature notices, will be posted here first. You can also find reasons for renting, but you won’t miss anything essential if you only read this blog.</div>
        <br/>

        {blogMap.map((obj,key) => (
        <div className="blogHeaderArticle">
          <div className="blogArtTitle">{obj.blogTitle}</div>
          <div className="blogDate">{obj.blogDate}</div>
          <div>{obj.blogTop3}</div>
          <a href={"/blog/" + transformTitle(obj.blogTitle)}><div className="readMoreButton">Read More</div></a>
        </div>
        ))}
    </div>
  );
}