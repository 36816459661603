import { Button, Container, makeStyles } from '@material-ui/core'
import ReactStars from "react-rating-stars-component";
import React from "react";
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import firebase from 'firebase'
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	starCenter: {
        display:'flex',
		justifyContent:'center',
        marginBottom:'20px'
	},
}));

export default function UserReview(props){
    const classes = useStyles()
    const [value, setValue] = React.useState(2);
    const reviewType = props.location.state.type || "ownerreview"
    const history = useHistory();

    const ownTitle = "Renter Review"
    const ownTitle2 = "Rate your experience on a scale 1-5 stars."
    const ownTitle3 = "Provide further feedback in the comment section."
    const ownQ1 = "Rate your satisfaction with the user."
    const ownQ2 = "Rate your satisfaction with RentEase."

    const renterTitle = "Owner Review"
    const renterTitle2 = "Rate your experience on a scale 1-5 stars."
    const renterTitle3 = "Provide further feedback in the comment section."
    const renterQ1 = "Were you satisfied with the item you rented?"
    const renterQ2 = "Was your lessor responsive and attentive?"
    const renterQ3 = "What is the likelyhood of renting items from this user again?"

    const com = "Comments:"


    const ratingChanged = (newRating) => {
        console.log(newRating);
      };
    const rentalStepProcess = () => {
        /*setProcessingSched(true);
        var scheduleNew = firebase.functions().httpsCallable('rentalReview');
        scheduleNew({"schedId": schedId,"step":stepId}).then((result) => {
                history.push('/myaccount');

          }).catch((err) =>{
                alert(err)
                setProcessingSched(false);
          });*/
    }

    const ownerRev = () => {
        alert("sending owner review")
        history.push('/myaccount')
    }
    const renterRev = () => {
        alert("sending renter review")
        history.push('/myaccount')
    }
    return(
        <div>
            <Container style={{textAlign:'center'}} maxWidth="sm">
                <h1>{reviewType == "ownerreview" ? renterTitle : ownTitle}</h1>
                <h2>{ownTitle2}</h2>
                <h3>{ownTitle3}</h3>

                <div>{ reviewType == "ownerreview" ? renterQ1 : ownQ1}</div>
                <div className={classes.starCenter}>
                    <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        activeColor="#ffd700"
                    />
                </div>
                <div>{ reviewType == "ownerreview" ? renterQ2 :  ownQ2}</div>
                <div className={classes.starCenter}>
                    <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        activeColor="#ffd700"
                    />
                </div>
                {reviewType == "ownerreview" ? 
                <div>
                <div>{renterQ3}</div>
                <div className={classes.starCenter}>
                    <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        activeColor="#ffd700"
                    />
                </div>
                </div>
                :
                <div></div>
                }
                <TextField
                    id="outlined-multiline-static"
                    label={com}
                    multiline
                    rows={4}
                    variant="outlined"
                    style={{width:'100%'}}
                    />
                <br/>
                <br/>
                <Link>
                    <Button variant="outlined" onClick={reviewType == "ownerreview" ? ownerRev : renterRev}>Submit Reviews</Button>
                </Link>
            </Container>
        </div>
    )
}