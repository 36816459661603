import { Typography } from '@material-ui/core'
import React , {useEffect,useState } from 'react'
import { useHistory } from 'react-router';
import { Button, Container, Divider } from '@material-ui/core'

export default function HelpDetails(props){

    console.log(props.location.state)
    const title = props.location.state.title
    const desc = props.location.state.desc
    const history = useHistory()
    const [showStripeLink,setShowStripeLink] = useState(false)

    useEffect(()=>{
        if (props.location.state.showStripe != undefined){
            setShowStripeLink(true)
        }
    },[])

    return(
        <div style={{marginBottom:'100px'}}>

        <Container maxWidth="md">
        <br/><br/>
        <div style={{textAlign:'center'}}>
            
            <h2 style={{marginBottom:'20px',textAlign:'center'}}>{title}</h2> 
            <Typography>{desc}</Typography>
            {showStripeLink ? <div><br/><br/>
            <Button className="buttonBackground" variant="outlined"><a target="_blank" href="http://www.stripe.com">Stripe</a></Button>

            </div> : <></>}
        </div>
        </Container>
        </div>
    )
}