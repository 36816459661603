import React, {useState , useEffect } from "react";
import { ListItemText, MenuItem, MenuList, Paper, ListItemIcon } from '@material-ui/core';
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { makeStyles } from '@material-ui/core/styles';
import {firestore} from '../../../config'
import Spinner from '../../Spinner'
import { useHistory } from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	headerTitle:{
		textAlign: 'center'
	},
	iconSize: {
		width: 50,
		height: 50
	},
	notifyCol:{
		color:'blue'
	}
 }));
 function isNumber(n){
    return typeof(n) != "boolean" && !isNaN(n);
}
export default function MyOwnerRentals(){
	const classes = useStyles();
	const user = useContext(UserContext);
	const history = useHistory()
	const [schedRentalData, setSchedRentalData] = React.useState([])
	const [queryComplete, setQueryComplete] = React.useState(false);

	useEffect(() => {
		if(user){
		const schedRef = firestore.collection('scheduled_rentals').where('renterData.owner','==',user.uid).where('completef', '==', false);
		const doc = schedRef.get().then(snapshot => {
				setSchedRentalData(snapshot.docs);	
				setQueryComplete(true)
		});
		}else{
			history.push('/myaccount');
		}
	}, []);
	if(queryComplete){
		return (
			<div>
                <Link to='/myaccount'>
                </Link>
				<h1 className={classes.headerTitle}>Owner</h1>
				<Paper>
					<MenuList>
						{schedRentalData.map(function(item,index){
							
							return (
                                <div key={item.data().id}>
								<MenuItem  component={Link} to={{ pathname: '/scheduledisplay', state: { schedData:item.data(), schedId: item.id, userType: "owner" } }}>
									<ListItemText primary={<div><div className={item.data().owner_notify ? classes.notifyCol : null}>{isNumber(item.data().renterData.status) ? 'In Progress' : item.data().renterData.status}</div><br/>Type : {item.data().renterData.rentalType}<br/>
									{item.data().renterData.renter_start} - {item.data().renterData.renter_end}</div>}/>
									<ListItemIcon>
										<img className={classes.iconSize} src={item.data().renterData.mainImage}/>
									</ListItemIcon>
								</MenuItem>
								<Divider/>
                                </div>
							)
						})}	
						{schedRentalData == [] || schedRentalData == 0 ? 
							<MenuItem>
									<ListItemText primary={<div style={{textAlign:'center'}}>No Rentals</div>}/>
								</MenuItem>
								:<div></div>
						}

					</MenuList>
				</Paper>
			</div>
			)
		}else{
			return (<div>
				<Spinner/>
			</div>)
		}
}      
