import React,{useEffect,useState} from 'react'
import HeroSection from '../../HeroSection'
import {homeObjOne} from './Data'
import SingleLineCardList from './HelpCardList.js'
import LowerCategoryBrowse from './LowerCategoryBrowse'
import StayInformedPanel from './StayInformedPanel'
import firebase from 'firebase'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import CustomizedInputBase from '../../SearchBar'
import TopCatDisplay from './TopCatDisplay'
import Info1 from './Info1'
import {Helmet} from "react-helmet";

function Home(){
  const [screenSize, setScreenSize] = useState(window.innerWidth)

  function resizeFun() {
    setScreenSize(window.innerWidth);
  }

  window.onresize = resizeFun

  useEffect(()=>{
	  console.log("ENV: " + process.env.REACT_APP_ENV)
	  //if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){ window.location.href = 'https://apps.apple.com/app/id1621378785'; }

	  //if(navigator.userAgent.toLowerCase().indexOf("android") > -1){ window.location.href = 'https://play.google.com/store/apps/details?id=com.renteasereactnative1.production&hl=en_US&gl=US'; }
  
  })

	return (
		<div style={{marginBottom:'100px'}}>
			{/*<Helmet>
            <title>RentEase</title>
            <link rel="canonical" href={window.location.href} />
            <meta name="description" content="With RentEase, lease anything from vehicles to equipment to kayaks with just a few easy clicks." />
	</Helmet> */}
			<div className="homepageBack">
				<div style={{paddingTop:'50px',fontWeight:'bolder',fontWeight:'36px',fontWeight:'bolder',fontSize:'xxx-large',background:'transparent',color:'white',textShadow:'4px 4px black'}}>Make renting easy.</div>
				<div style={{fontSize:'small',fontWeight:'bold',maxWidth:'600px',paddingTop:'20px',margin:'auto',background:'transparent',color:'white',textShadow:'2px 2px black'}}>With RentEase, lease anything from vehicles to equipment to kayaks with just a few easy clicks.
We’ll be your rental business so you can rent with ease.</div>
			</div>
			<div style={{padding:'20px',background:'white',borderRadius:'20px',marginTop:'-50px',marginLeft:'auto',marginRight:'auto',maxWidth:'800px',boxShadow:'0 3px 10px rgb(0 0 0 / 0.2)'}}>
				<CustomizedInputBase />
			</div>

			<TopCatDisplay/>
			<SingleLineCardList/>
			<div style={{marginBottom:'50px'}}>
				<div className="titleHeaderText">Let your expensive equipment pay for itself.</div>
				<div style={{textAlign:'center',marginBottom:'10px'}}>Share your equipment when you're not using it and earn extra cash in just 10 minutes.</div>
				<div style={{textAlign:'center'}}>
					<Link to='/addnewrental'>
						<Button className="gradient-button buttonBackground" style={{width:'100%',maxWidth:'500px'}} variant="outlined">List your equipment</Button>
					</Link>
				</div>
			</div>
			<StayInformedPanel />
		</div>

	)
	
}

export default Home