import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Link} from 'react-router-dom'
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
	headerTitle:{
		textAlign: 'center'
	},
	iconSize: {
		width: 30
	}
 }));

export default function FAQ() {
  const classes = useStyles();
  const history = useHistory()

  const helpInfo = [
	{
		tit:"Is there anything I can’t rent?", desc:"You are unable to list or rent firearms or any other illegal items in your state."
	},
	{
		tit:"What if I don’t want to charge sales tax?",desc:``
	},
	{
		tit:"Do I need insurance to rent an item?",desc:"The lister of the item will include in the description whether or not insurance is necessary.  Although insurance may not be needed in the situation it would greatly help cover the cost if you may have broken the item."
	},
	{
		tit:"What if I don’t get my item back?",desc:"If your item is not returned to you at the time originally planned, or is in worse condition than  when rented, send an email to JOHNBAKKE@rentease.com.  We will strive to do our best to get the situation controlled."
	},
	{
		tit:"How do I get my deposit back.",desc:""
	},
	{
		tit:"What are the fees for.",desc:""
	},
	{
		tit:"How do I know what to charge?",desc:"It’s completely up to you as a lister, you could go online and compare it to the original price.  You could also look throughout our app and see if anything is similar and set it to a similar price."
	},
	{
		tit:"Am I liable if someone hurts themselves using my item?",desc:"No, prior to renting an item out we ask that all renters must sign a form stating that neither the lister or Rentease will be considered liable in the occurrence of an injury related to the item rented."
	},
	{
		tit:"Can I expense or depreciate my item.",desc:""
	},
	{
		tit:"What if I break the item I rented?",desc:"Prior to renting an item you must sign a form stating that if the item breaks while you are renting it out you just replace it.  You must take full responsibility for breaking the item."
	},
	{
		tit:"Who do I call if I have questions.",desc:""
	},
	{
		tit:"What if I don’t want renters to know where I live?",desc:"You can choose a different pickup or drop off location than your home in the event that you don’t want your address revealed."
	}
  ]
  return (
    <div>
	<h1 className={classes.headerTitle}>FAQ</h1>
      <Paper>
        <MenuList>
          <MenuItem key={1} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[0].tit, desc:helpInfo[0].desc } }}>
			<ListItemText primary="Is there anything I can’t rent?" />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
          <MenuItem key={2} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[1].tit, desc:helpInfo[1].desc } }}>
			<ListItemText primary="What if I don’t want to charge sales tax?" />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
          <MenuItem key={3} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[2].tit, desc:helpInfo[2].desc } }}>
			<ListItemText primary="Do I need insurance to rent an item?" />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
		  <MenuItem key={4} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[3].tit, desc:helpInfo[3].desc } }}>
			<ListItemText primary="What if I don’t get my item back?" />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
		  <MenuItem key={5} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[4].tit, desc:helpInfo[4].desc } }}>
			<ListItemText primary="How do I get my deposit back." />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
		  <MenuItem key={6} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[5].tit, desc:helpInfo[5].desc } }}>
			<ListItemText primary="What are the fees for." />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
		  <MenuItem key={7} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[6].tit, desc:helpInfo[6].desc } }}>
			<ListItemText primary="How do I know what to charge?" />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
		  <MenuItem key={8} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[7].tit, desc:helpInfo[7].desc } }}>
			<ListItemText primary="Am I liable if someone hurts themselves using my item?" />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
		  <MenuItem key={9} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[8].tit, desc:helpInfo[8].desc } }}>
			<ListItemText primary="Can I expense or depreciate my item." />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
          <MenuItem key={10} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[9].tit, desc:helpInfo[9].desc } }}>
			<ListItemText primary="What if I break the item I rented?" />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
          <MenuItem key={11} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[10].tit, desc:helpInfo[10].desc } }}>
			<ListItemText primary="Who do I call if I have questions." />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
          <MenuItem key={12} component={Link} to={{ pathname: '/helpdetails', state: {title:helpInfo[11].tit, desc:helpInfo[11].desc } }}>
			<ListItemText primary="What if I don’t want renters to know where I live?" />
			<ListItemIcon>
				<ArrowForwardIosIcon/>
			</ListItemIcon>
		  </MenuItem>
		  <Divider />
        </MenuList>
      </Paper>
    </div>
  );
}