import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from 'react-router-dom';


const useStyles = makeStyles({
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cover: {
    width: 50,
    height: 50,
	marginBottom:10,
  margin:'auto'
  },
});

export default function OutlinedHelpCard({titleText,descText,imageSrc}) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className="helpInfoCards darkBack" variant="outlined">
      <CardContent>
	  <CardMedia
		className={classes.cover}
        image={imageSrc}
      />
        <Typography variant="h5" component="h2" style={{textAlign:'center',paddingTop:'5px'}}>
		{titleText}
        </Typography>
        <Typography variant="body2" component="p" style={{textAlign:'center',paddingTop:'5px'}}>
		{descText}
        </Typography>
      </CardContent>
    </Card>
  );
}