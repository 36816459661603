import React , {useState}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import OutlinedHelpCard from './HelpCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    //backgroundColor: '#fafafa',
	marginBottom: '15px'
  },
  title: {
    color: 'white',
	fontSize: '13px;'
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  imagecont:{
	width:'350px'
  }
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */

const cardData = [
{
	title: 'Cleaned & Disinfected Equipment',
	desc: 'The enhanced cleaning policy requires hosts to clean & disinfect their equipment prior to rental.',
	img: 'images/Cleaned-&-Disinfected-Equipment.png'
},
{
	title: 'Rent an item 24 hours a day',
	desc: 'List or rent an item with RentEase at your convenience, any time of the day.',
	img: 'images/Rent-an-item-24-hours-a-day.png'
},
{
	title: 'Why rent instead of buy',
	desc: 'RentEase is here to help you save a buck by helping you get the equipment you need without having to buy.',
	img: 'images/Why-rent-instead-of-buy.png'
},
{
	title: 'Why you shouldn\'t own depreciating assets',
	desc: 'The equipment you need to get the job done loses its value quickly. Consider renting for those projects that come up.',
	img: 'images/Why-you-shouldn\'t-own-depreciating-assets.png'
},
{
	title: 'Help other workers in your industry',
	desc: 'Make some cash while helping people who work in a similar industry to your own.',
	img: 'images/Help-other-workers-in-your-industry.png'
}
];

export default function SingleLineCardList() {
  const classes = useStyles();
  const [screenSize, setScreenSize] = useState(window.innerWidth)

  function resizeFun() {
    setScreenSize(window.innerWidth);
  }

  window.onresize = resizeFun
  
  const numbers = [1, 2, 3, 4, 5];
	const listItems = cardData.map((item,index) =>
		<OutlinedHelpCard key={index} titleText={item.title} descText={item.desc} imageSrc={item.img}/>
	);

  return (
    <div style={{padding:'15px',background:'lightgray',borderRadius:'20px'}}>
      <GridList style={{display:'flex',justifyContent:'space-evenly'}}>
			{listItems}
      </GridList>

    </div>
  );
}