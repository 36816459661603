import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from '../../../config'
import Spinner from '../../Spinner'
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { ListItemText, ListItemIcon } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';


import Divider from '@material-ui/core/Divider';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		textAlign: 'center'
	},
	iconSize: {
		width: 50,
		height: 50
	},
	notifyCol: {
		color: 'blue'
	}
}));

export default function Contacts(props) {
	const user = useContext(UserContext);
	const [contactsArray, setContactsArray] = React.useState([])
	const [queryComplete, setQueryComplete] = React.useState(false);
	const classes = useStyles();
	const history = useHistory();
	console.log(props)
	var myUid = ""
	var openMessageUserUid = ""
	var openMessageName = ""
	var openMessagePhoto = ""
	var openMessageEmail = ""
	var rentalName = ""
	var rentalImage = ""
	if (props.location.query) {
		openMessageUserUid = props.location.query.toUserUid
		openMessageName = props.location.query.toUsername
		openMessagePhoto = props.location.query.toUserPhoto
		openMessageEmail = props.location.query.toUserEmail
		rentalName = props.location.query.rname
		rentalImage = props.location.query.rimage
	}

	useEffect(() => {
		if(user){
		const myUid = user.uid
		var messageExists = false;
		const schedRef = firestore.collection('messages').where('users', 'array-contains',user.uid)
		const doc = schedRef.get().then(snapshot => {
            setContactsArray(snapshot.docs);	
			setQueryComplete(true)
            snapshot.forEach((item)=>{
				console.log(item.data())
                if(item.data().users.includes(myUid) && item.data().users.includes(openMessageUserUid)){
					messageExists = true;
				}
            })
			if(!messageExists && openMessageUserUid != null && openMessageUserUid != "" ){
				alert("create new messages")
				const addData = {
					chatRentalImage: rentalImage,
					chatRentalName: rentalName,
					u1_email: user.email,
					u1_id: myUid,
					u1_name: user.displayName,
					u1_notify: false,
					u1_photo: user.photoURL,
					u2_email: openMessageEmail,
					u2_id: openMessageUserUid,
					u2_name: openMessageName,
					u2_notify: true,
					u2_photo: openMessagePhoto,
					users: [myUid,openMessageUserUid],
					messages:[{text:'New Message Created.',sender_id:myUid, sendername: user.displayName,time: new Date().getTime()}]
				}
				const addRef = firestore.collection('messages').add(addData);
				addRef.then((res)=>{
					console.log(res.id)
					history.push({
						pathname: '/message',
						  state: {messageD: addData, messageId:res.id} ,// your data array of objects
					  })

				})

			}
		})
		} else {
			history.push('/login')
		}
	}, []);
	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
		"July", "August", "Sep", "Oct", "Nov", "Dec"
		];
	const formatTimeM = (time) =>{
		var date = new Date(time)
		return monthNames[date.getMonth()] + " " + date.getDate() + ', ' + date.getFullYear()
	}

	if (queryComplete) {
		return (
			<div>
				<h1 className="titleHeaderText">Contacts</h1>
				<Paper>
					<MenuList>
						{contactsArray.map(function (item, index) {
							var noUse1phot = item.data().u1_photo == "" 
							var noUse2phot = item.data().u2_photo == "" 
							return (
								<div>
									<MenuItem style={{ display: 'flex', justifyContent: 'space-between' }} key={item.data().id} component={Link} to={{ pathname: '/message', state: { mesid: item.id, mesD: item.data() } }}>
										<div style={{ display: 'flex',width:'100%' }}>
										{user.uid == item.data().u1_id && noUse2phot ? 
											<AccountCircleIcon style={{width:'50px',height:'50px'}}/> : <></>
										}
										{user.uid == item.data().u1_id && !noUse2phot ? 
											<img style={{ borderRadius: '50%' }} src={item.data().u2_photo} width="50" height="50" />
											: <></>
										}
										{user.uid == item.data().u2_id && noUse1phot ? 
											<AccountCircleIcon style={{width:'50px',height:'50px'}}/> : <></>
										}
										{user.uid == item.data().u2_id && !noUse1phot ? 
											<img style={{ borderRadius: '50%' }} src={item.data().u1_photo} width="50" height="50" />
											: <></>
										}
											<div style={{width:'100%'}}>
												<div style={{ marginLeft: '10px' }}>
													<div style={{display:'flex',justifyContent:'space-between'}}>
														<div style={{whiteSpace:'normal'}}><b>{user.uid == item.data().u1_id ? item.data().u2_name : item.data().u1_name }</b></div>
														<div>
															{item.data().messages.length != 0 ?  formatTimeM(item.data().messages[0].time) : 
														<div></div>}
														</div>
													</div>
												</div>
												<div>
													<div style={{whiteSpace: 'pre-wrap',margin:'0px 12px'}}>{item.data().messages.length != 0 ? item.data().messages[item.data().messages.length - 1].text : ""}</div>
												</div>
											</div>

										</div>
									</MenuItem>
									<Divider />
								</div>
							)
						})}
						{contactsArray == [] || contactsArray.length == 0 ? 
							<MenuItem>
									<ListItemText primary={<div style={{textAlign:'center'}}>No Messages</div>}/>
								</MenuItem>
								:<div></div>
						}		
					</MenuList>
				</Paper>
			</div>
		)
	} else {
		return (<div>
			<Spinner />
		</div>)
	}
}