import React from 'react';
import './HeroSection.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import CustomizedInputBase from './SearchBar'
import TopCatDisplay from './pages/HomePage/TopCatDisplay'
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart
}) {
  const user = useContext(UserContext);
  return (
    <>
				<CustomizedInputBase />
        <div className="titleHeaderText">Find everything you need without going far.</div>
				<TopCatDisplay />
      </>
  );
}

export default HeroSection;