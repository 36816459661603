import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};
export const signInWithEmail = (data, email, password) => {
  auth.signInWithEmailAndPassword(email,password).catch((err)=>{
      alert(err.message)
  })
}
export const signUpWithEmail = (data,email,password) =>{
  return auth.createUserWithEmailAndPassword(email,password);
}

export const firebaseConfig =  {
    apiKey: "AIzaSyBp3eML6mX7KAkKIn55tXbSKlsYNvlDtzQ",
    authDomain: "renteasedev.firebaseapp.com",
    databaseURL: "https://renteasedev.firebaseio.com",
    projectId: "renteasedev",
    storageBucket: "renteasedev.appspot.com",
    messagingSenderId: "765217138452",
    appId: "1:765217138452:web:d892b4d60f493624f3c401"
  };
  
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();