import { Button, Container, Divider } from '@material-ui/core'
import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import { useHistory } from 'react-router';
import {firestore} from '../../../config'
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import firebase from 'firebase'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        left: '5%',
        right: '5%',
        bottom: '9%'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const formatTimeM = (time) =>{
    var date = new Date(time)
    var hr = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var min = date.getMinutes().toString().length < 2 ? "0" + date.getMinutes() : date.getMinutes() 
    return hr +':'+ min + " " + (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()
}

export default function Message(props) {
    const user = useContext(UserContext);
    const classes = useStyles();
    const[messages, setMessages] = React.useState([])
    const[msg, setMsg] = React.useState("")
    const history = useHistory()
    console.log(props)
    var msgData = props.location.state.mesD
    var newMessageId = props.location.state.mesid
    if(msgData == undefined){
        msgData = props.location.state.messageD
        newMessageId = props.location.state.mesid
    }
    console.log(msgData)
    var mainIm = msgData.chatRentalImage
    var user1Image = msgData.u1_photo
    var user2Image = msgData.u2_photo
    var user1Id = msgData.u1_id
    var user2Id = msgData.u2_id
    var rentalTitle = msgData.chatRentalName
    var messageId = props.location.state.mesid

    useEffect(() => {
        setMessages(msgData.messages)
        //const doc = firestore.collection('messages').doc(messageId);

        /*const observer = doc.onSnapshot(docSnapshot => {
        console.log(docSnapshot.data())
        setMessages(docSnapshot.data().messages)
        // ...
        }, err => {
        console.log(`Encountered error: ${err}`);
        });*/
    },[]);

   const sendMsg = (event)=>{

       if(msg == ''){
           return;
       }

       var data = {
        sender_id:user.uid,
        sendername: user.displayName,
        text:msg,
        time:new Date().getTime()
       }
       var newMessages = messages
       newMessages.push(data)
       setMessages(newMessages)
       console.log(messages)
       setMsg("")
       console.log("message start ")

       const cityRef = firestore.collection('messages').doc(messageId);
       const res = cityRef.update({messages: newMessages}).then((res)=>{
           console.log("message complete ")
            var sendId = user.uid
            if(user.uid == user1Id){
                sendId = user2Id
            }
            console.log("funciton call")
                var sendmesg = firebase.functions().httpsCallable('checkSendDeviceNotify');
                sendmesg({"sentToId":sendId}).then((result) => {
                    console.log("funciton complete")
                    console.log(result)

                })
       }).catch((err)=>{
           console.log(err)
       })
   }
    return (<div>
        <Container>
            <div style={{ display: 'flex', margin: '10px 0px' }}>
                <img src={mainIm} width='90' heigh='90' style={{width:'90px',height:'90px'}}/>
                <div style={{ margin: '10px' }}>{rentalTitle}<br/>Id : {newMessageId}</div>
            </div>
            <Divider style={{ margin: '10px 0px' }} />
            <div id="mescont" style={{height:'350px',overflow:'scroll'}}>
                {Array.from(messages).map(function (item, index) {
                    return (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    {item.sender_id == user1Id ? 
                                    <>{user1Image != "" ? <img style={{ borderRadius: '50%', minWidth: '50px', marginRight: '10px' }} src={user1Image} width="50" height="50" /> : <><AccountCircleIcon style={{width:'50px',height:'50px'}}/></>}</>
                                    :
                                    <>{user2Image != "" ? <img style={{ borderRadius: '50%', minWidth: '50px', marginRight: '10px' }} src={user2Image} width="50" height="50" /> : <><AccountCircleIcon style={{width:'50px',height:'50px'}}/></>}</>
                                    }
                                </div>
                                <div style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                            <b>{item.sendername}</b> <div style={{ fontSize: '10px', marginRight:'10px' }}>{formatTimeM(item.time)}</div>
                                        </div>
                                    </div>
                                    <div>
                                        {item.image != undefined ? <div><img style={{width:'200px',height:'200px'}} src={item.image}/></div>
                                        :<div>{item.text}</div>}
                                    </div>
                                </div>

                            </div>
                            <Divider style={{ margin: '10px 0px' }} />
                        </>
                    )
                })}
            </div>
            <Paper className={classes.root}>
                <InputBase
                    className={classes.input}
                    placeholder="Send a Message"
                    inputProps={{ 'aria-label': 'Send a Message' }}
                    value={msg}
                    onChange={(event)=> {
                        setMsg(event.target.value)
                    }}
                />
                <IconButton className={classes.iconButton} aria-label="search" onClick={(event)=>{
                    sendMsg();
                }}>
                    <SendIcon />
                </IconButton>
            </Paper>
        </Container>
    </div>)
}