import React from 'react'
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';

export default function ContactUs (){
    const history = useHistory();

    return (
        <>
        <Container maxWidth="sm" style={{textAlign:'center'}}>
        
            <div className="titleHeaderText">RentEase</div>
            <br/>
            <h2 style={{textAlign:'center'}} >Contact Us</h2>
            <br/>
            <div>Contact us by Emailing : <a href="mailto:service@rentease.com">service@rentease.com</a></div>
            <br/>
        </Container>
        </>
    )
}