import React, { useState,useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import Spinner from '../../Spinner'
import { useHistory } from "react-router-dom";
import { auth,firestore } from '../../../config'


const useStyles = makeStyles((theme) => ({
	headerTitle: {
		textAlign: 'center'
	},
	iconSize: {
		width: 30,
		height: 30
	}
}));

export default function MyAccount() {
	const classes = useStyles();
	const user = useContext(UserContext);
	const history = useHistory()
	const [ownerNotfiy, setOwnerNotify] = useState(0)
	const [renterNotify, setRenterNotify] = useState(0)

	//console.log(user);
	const logOff = () => {
		console.log('signing off');
		auth.signOut()
		history.push('/');
	}
	useEffect(() => {
		if (user) {
			const ownerRef = firestore.collection('scheduled_rentals').where('renterData.owner', '==', user.uid);
			const doc = ownerRef.get().then(snapshot => {
				var ownerCount = 0;
				snapshot.forEach((doc) =>{
					if(doc.data().owner_notify){
						ownerCount +=1
					}
				});
				setOwnerNotify(ownerCount)

			});
			const renterRef = firestore.collection('scheduled_rentals').where('renterData.renter_uuid', '==', user.uid);
			const doc2 = renterRef.get().then(snapshot => {
				var renterCount = 0;
				snapshot.forEach((doc) =>{
					if(doc.data().renter_notify){
						renterCount +=1
					}
				});
				setRenterNotify(renterCount)

			});
		} else{
            history.push('/login')
        }
	}, []);
	if (user) {
		return (
			<div style={{marginBottom:'100px'}}>
				<h1 className="titleHeaderText" >My Account</h1>
				<div>{user.displayName}</div><br />
				<div>{user.email}</div>
				<br />
				<Paper className="darkBack">
					<MenuList className="helpPageTable">
						<MenuItem component={Link} to="/renterrentals">
							<ListItemText primary="Renter" />
							{renterNotify != 0 ? <div>New({renterNotify})</div> : <div></div>}
							<ListItemIcon>
								<img className={classes.iconSize} src='images/Renter.png' />
							</ListItemIcon>
						</MenuItem>
						<Divider className="darkDivider"/>
						<MenuItem component={Link} to="/ownerrentals">
							<ListItemText primary="Owner" />
							{ownerNotfiy != 0 ? <div>New({ownerNotfiy})</div> : <div></div>}
							<ListItemIcon>
								<img className={classes.iconSize} src='images/Owner.png' />
							</ListItemIcon>
						</MenuItem>
						<Divider className="darkDivider"/>
						<MenuItem component={Link} to="/mylistings">
							<ListItemText primary="My Listings" />
							<ListItemIcon>
								<img className={classes.iconSize} src='images/My-Listings.png' />
							</ListItemIcon>
						</MenuItem>
						<Divider className="darkDivider"/>
						<MenuItem component={Link} to="/personalinfo">
							<ListItemText primary="Account Info" />
							<ListItemIcon>
								<img className={classes.iconSize} src='images/Account-Info.png' />
							</ListItemIcon>
						</MenuItem>
						<Divider className="darkDivider"/>
						<MenuItem component={Link} to="/myfavorites">
							<ListItemText primary="Favorites" />
							<ListItemIcon>
								<img className={classes.iconSize} src='images/Renter.png' />
							</ListItemIcon>
						</MenuItem>
						<Divider className="darkDivider"/>
						<MenuItem component={Link} to="/mypayments">
							<ListItemText primary="Payments" />
							<ListItemIcon>
								<img className={classes.iconSize} src='images/Payments.png' />
							</ListItemIcon>
						</MenuItem>
						<Divider className="darkDivider"/>
						<MenuItem component={Link} to="/notifications">
							<ListItemText primary="Allow Notifications" />
							<ListItemIcon>
								<img className={classes.iconSize} src='images/Renter.png' />
							</ListItemIcon>
						</MenuItem>
						<Divider className="darkDivider"/>
						<MenuItem onClick={logOff}>
							<ListItemText primary="Logout" />
							<ListItemIcon>
								<img className={classes.iconSize} src='images/Logout.png' />
							</ListItemIcon>
						</MenuItem>
					</MenuList>
				</Paper>
			</div>
		);
	} else {
		return (
			<div>
				<Spinner />
			</div>
		)
	}
}