import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  centerStuff: {
	textAlign: 'center',
    marginTop: '75px'
  },
}));

export default function Spinner() {
  const classes = useStyles();

  return (
    <div className={classes.centerStuff}>
      <CircularProgress />
    </div>
  );
}