import React, {useState} from 'react'
import { Link, useHistory } from "react-router-dom";
import firebase from 'firebase'
import { Button, Container, Divider } from '@material-ui/core';
import {makeStyles} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress';
import Spinner from '../../Spinner'

const useStyle = makeStyles((theme) => ({
    buttonheight: {
        height:'120px'
    },
    calendarWidth:{
        width:'50%'
    }
}));

export default function SchduleActionPage(props){
    console.log(props.location.state);
    const history = useHistory()
    const schedId = props.location.state.schedData
    const stepId = props.location.state.stepId 
    const detailD = props.location.state.detailData
    const [processingSched, setProcessingSched] = useState(false);
    const classes = useStyle();
// " + detailD. + " 
    var topTitle = ""
    var leftButton = ""
    var rightButton = "Report an issue." 
    switch(stepId){
        case '001' : 
        topTitle = "You are scheduled to deliver " + detailD.title+ "  to " + detailD.renterN + " at " + detailD.pickupTime + " " + detailD.pickupDate + " at " + detailD.pickupLocation + "."
        leftButton = "I will be able to deliver " + detailD.title + " to " + detailD.renterN + "  on time."
        break;
        case '002' : 
        topTitle = "You are scheduled to accept delivery of " + detailD.title+ " from " + detailD.ownerN + " at " + detailD.pickupTime + " " + detailD.pickupDate + " at " + detailD.pickupLocation + "."
        leftButton = "I will be able to accept delivery of " + detailD.title+ " from " + detailD.ownerN + " on time."
        break;
        case '003' : 
        topTitle = "You are scheduled to deliver " + detailD.title+ " to " + detailD.renterN + " at " + detailD.pickupTime+ " " + detailD.pickupDate+ " at " + detailD.pickupLocation+ "."
        leftButton = "I have delivered " + detailD.title+ " to " + detailD.renterN + "."
        break;
        case '004' : 
        topTitle = "You were scheduled to accept delivery of " + detailD.title+ " from " + detailD.ownerN + " at " + detailD.pickupTime+ " " + detailD.pickupDate+ " at " + detailD.pickupLocation+ ". "
        leftButton = detailD.ownerN + "has delivered " + detailD.title+ " to me."
        break;
        case '006' : 
        topTitle = "You are scheduled to return " + detailD.title+ " to " + detailD.ownerN + " at " + detailD.pickupTime+ " " + detailD.pickupDate+ " at " + detailD.pickupLocation+ "."
        leftButton = "I have returned " + detailD.title+ " to " + detailD.ownerN + "."
        break;
        case '007' : 
        topTitle = "You were scheduled to accept the return of " + detailD.title+ " from " + detailD.renterN + " at " + detailD.pickupTime+ " " + detailD.pickupDate+ " at " + detailD.pickupLocation+ "."
        leftButton = detailD.renterN + " has returned " + detailD.title+ " to me."
        break;
    }

    const rentalStepProcess = () => {
        setProcessingSched(true);
        var scheduleNew = firebase.functions().httpsCallable('processScheduledRental');
        scheduleNew({"schedId": schedId,"step":stepId}).then((result) => {
                console.log(result);
                if(stepId == '006'){
                    history.push({
                        pathname: '/userreview',
                        state: { type: "ownerreview" }
                      })
                }else if(stepId == '007'){
                    history.push({
                        pathname: '/userreview',
                        state: { type: "renterreview" }
                      })
                }else{
                history.push('/myaccount');
                }

          }).catch((err) =>{
                alert(err)
                setProcessingSched(false);
          });
    }
    function goBack() {
        history.goBack()
    }
    const leftAction = () => {
        rentalStepProcess();
    }
    const rightAction = () => {
        history.push({
            pathname: '/reportissue',
            state: { detail: detailD}
          })
    }
    return (
        <div>
                <div style={{textAlign:'center'}}>
                    <h1>RentEase</h1>
                    <Divider style={{margin:'20px 0px'}}/>
                    <LinearProgress style={{margin:'20px 0px'}} variant="determinate" value={50} />
                    <h2>{topTitle}</h2>
                    <br/>
                    <div>Please select one of the following:</div>
                </div>
                <Divider style={{margin:'20px 0px'}}/>
                {processingSched ?
                <Spinner/>
                :
                <div style={{display:'flex',justifyContent:'space-evenly'}}>
                    <div className={classes.calendarWidth}>
                        <Button className={classes.buttonheight} variant="outlined" onClick={leftAction}>{leftButton}</Button>
                    </div>
                    <div>
                        <Button className={classes.buttonheight} variant="outlined" onClick={rightAction}>{rightButton}</Button>
                    </div>
                </div>
                }
        </div>
    )

}