import React, {useState} from "react";
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import firebase from 'firebase'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center'
  },
  textField: {
  },
  centerF: {
	textAlign: 'center'
  },
  googleButt: {
	height: '36px'
  },
  googleImg:{
	height: '26px',
	margin: '0px 8px'
  }
}));

const ForgotPassword = () => {
	  const classes = useStyles();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

  const resetUserPass = (email) =>{
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        // Password reset email sent!
        // ..
        alert("Password reset as been sent.")
      })
      .catch((error) => {
        var errorMessage = error.message;
        setError(errorMessage);
        // ..
      });
  }

  return (
    <div className={classes.root}>
				<Container maxWidth="sm" style={{border:'1px solid black',margin:'20px',borderRadius:'15px',padding:'10px'}}>
      <h1 className="titleHeaderText">Forgot Password</h1>
	  <h2>Please Enter Email</h2>
      <div >
        {error !== null && <div>{error}</div>}
        <form className="">
		<TextField
          id="outlined-full-width"
          label="User Email"
          placeholder="Enter email here"
          helperText="An email will be sent with next information."
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          value={email}
          onChange={(val)=>{setEmail(val.value)}}
          variant="outlined"
        />
		<Button onClick = {(event) => {resetUserPass( email)}} variant="outlined">Submit</Button>
        </form>
      </div>
	  </Container>
    </div>
  );
};
export default ForgotPassword;