import React, {useState, useEffect} from 'react'
import { Link, useHistory } from "react-router-dom";
import { Button, Container, Divider } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import firebase from 'firebase'


export default function ReportIssue(props){
    const history = useHistory()
    const details = props.location.state.detail
    const [displayType,setDisplayType] = useState("")
    const [selectedRadioNum,setSelectedRadioNum] = useState(null)
    const [pageTitle, setPageTitle] = useState("Report an Issue.")
    console.log(details)

    function goBack() {
        history.goBack()
    }
    var l1 = "I was not able to deliver " + details.title + "  to " + details.renterN + " ."
    var l2 = details.renterN + " did not show up to pick up " + details.title + "."
    var l3 = details.renterN + " rejected " + details.title + " because it didn’t meet specifications."
    var l4 = details.renterN + " damaged " + details.title + "."
    if( details.userType == "renter"){
        l1 = "I was not able to deliver " + details.title + "  to " + details.ownerN + " ."
        l2 = details.ownerN + " did not show up to dropoff " + details.title + "."
        l3 = details.ownerN + " rejected " + details.title + " because it didn’t meet specifications."
        l4 = details.renterN + " damaged " + details.title + "."
    }else{

    }
    var l5 = "Other."
    // " + details. + " 
    const [line1Val ,setLine1Val] = useState(l1)
    const [line2Val ,setLine2Val] = useState(l2)
    const [line3Val ,setLine3Val] = useState(l3)
    const [line4Val ,setLine4Val] = useState(l4)
    const [line5Val ,setLine5Val] = useState(l5)
    


    useEffect(()=>{

    },[])

    const submitTender = () =>{
        if(displayType == ""){
           if(selectedRadioNum == 4){
               // set to damage display
                setDisplayType("damage")
                setPageTitle("Process Deposit")
                setLine1Val("25%")
                setLine2Val("50%")
                setLine3Val("75%")
                setLine4Val("100%")
            }else if(selectedRadioNum == 5){
                // set to issue page
                console.log("issue page")
            }else{
                // cancel rental
                console.log("canceling rental")
            }
        }else if (displayType == "damage"){
            // charge selected damage 
            if(selectedRadioNum == 1){
                chargeDeposit(0.25)
            }
            if(selectedRadioNum == 2){
                chargeDeposit(0.5)
            }
            if(selectedRadioNum == 3){
                chargeDeposit(0.75)
            }
            if(selectedRadioNum == 4){
                chargeDeposit(1)
            }
            if(selectedRadioNum == 5){
                // issue page 
            }
        }
        console.log(selectedRadioNum)
    }
    function chargeDeposit(depositPercent){
        console.log("charging " + depositPercent )
        var processDeposit = firebase.functions().httpsCallable('processScheduledRental');
        processDeposit({"schedId":details.schedule_id,"step":"210","depositAmount":depositPercent}).then((result) => {
                alert("Deposit Sent to renter.")
                history.push("/myaccount")
          }).catch((err) =>{
                alert(err)
          });	
    }
     
    function refundSchedule(){
        console.log("refunding")
        var refundCharge = firebase.functions().httpsCallable('refundCharge');
        refundCharge({"schedId":details.schedule_id}).then((result) => {
                alert("Rental Canceled and Refunded.")
                history.push("/myaccount")
          }).catch((err) =>{
                alert(err)
          });	
    }
    return(<div>
            <div style={{textAlign:'center'}}>
                <h1>{pageTitle}</h1>
            </div>
            <LinearProgress style={{margin:'20px 0px'}} variant="determinate" value={70} />
            <br/>
            <br/>
            <Container maxWidth="xs">
                <div>
                    <input type="radio" value="Male" name="gender" onChange={()=>{setSelectedRadioNum(1)}}/> {line1Val} <br/><br/>
                    <input type="radio" value="Female" name="gender" onChange={()=>{setSelectedRadioNum(2)}}/> {line2Val}  <br/><br/>
                    <input type="radio" value="Other" name="gender"onChange={()=>{setSelectedRadioNum(3)}}/> {line3Val}  <br/><br/>
                    <input type="radio" value="Other" name="gender" onChange={()=>{setSelectedRadioNum(4)}}/> {line4Val}  <br/><br/>
                    <input type="radio" value="Other" name="gender" onChange={()=>{setSelectedRadioNum(5)}}/> {line5Val}  <br/><br/>
                </div>
                <Button variant="outlined" onClick={()=>{submitTender()}}>Continue</Button>
            </Container>
         </div>  
        )
}