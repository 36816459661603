import { Container } from '@material-ui/core';
import React, { useRef,useEffect, useState } from 'react';
import {Helmet} from "react-helmet";

export default function VideoPage(){
        let data = {
            "@context": "http://schema.org",
            "@type": "VideoObject",
            "name": "RentEase videos will help you with the rental process.",
            "description": "Learn the new user process.",
            "thumbnailUrl": "https://rentease.com/images/RentEase-Namestyle.svg",
            "uploadDate": "2023-05-11T08:00:00Z",
            "publisher": {
              "@type": "Organization",
              "name": "RentEase"
            },
            "contentUrl": "https://rentease.com/videos"
          }

    return(
        <Container maxWidth="sm">
            <Helmet>
                <meta name="description" content="RentEase videos will help you with the rental process."/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <meta name="keywords" content="video, rent, user"/>
                <script className='structured-data-list' type="application/ld+json">{JSON.stringify(data)}</script>
            </Helmet> 
            <div style={{textAlign:'center',fontSize:'large',marginBottom:'40px',marginTop:'80'}}>RentEase videos will help you with the rental process.</div>
            <div className='hrTitleSpace'>New User Process</div>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/MA3cJUalY-o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <hr className='hrSpace'/>
            <div className='hrTitleSpace'>Add Rental Process</div>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/EnEP7_lCtKc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <hr className='hrSpace'/>
            <div className='hrTitleSpace'>Rental Process</div>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/Dckyg_7KpyM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <hr/>


        </Container>
    )
}