import React, {useState , useEffect } from "react";
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import {firestore} from '../../../config'
import Spinner from '../../Spinner'
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
  },
  centerF: {
	textAlign: 'center'
  },
  googleButt: {
	height: '36px'
  },
  googleImg:{
	height: '26px',
	margin: '0px 8px'
  }
}));

const PersonalInfo = () => {
	const user = useContext(UserContext);
	const history = useHistory()
	const [userDocData, setUserDocData] = React.useState({
		addressline1: '',
		addressline2: '',
		addresscity: '',
		addressstate: '',
		addresszip: '',
	})
	const classes = useStyles();
	
	const [hasUpdated, setHasUpdated] = React.useState(false);
	const [queryComplete, setQueryComplete] = React.useState(false);

	useEffect(() => {
		if(user){
		const cityRef = firestore.collection('users').doc(user.uid);
		const doc = cityRef.get().then(res => {
				setUserDocData(res.data());	
				setQueryComplete(true)
				console.log(res.data());
		});
		}else{
			history.push('/myaccount');
		}
	}, []);
	
	console.log(userDocData);
if(queryComplete){
  const updateFieldData = (idd, value) =>{
	  setHasUpdated(true)
	  var userData = JSON.parse(JSON.stringify(userDocData))
	  userData[idd] = value;
	  setUserDocData(userData);
	  
  }
  const updateValues = () => {
	  console.log(userDocData);
	const res = firestore.collection('users').doc(user.uid).update(
		userDocData
	);
	res.then(res => {
		console.log(res)
		alert("Update Sucessful!");
    history.goBack();
	})
  };

  return (
    <div style={{marginBottom:'200px'}}>
    <div className={classes.centerF}>
				<Container maxWidth="sm">
      <h1>Personal Information</h1>
      <div >
        <form className="">
		<TextField
          label="Display Name"
          placeholder="Enter email here"
          helperText="An email will be sent with next information."
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
		  value={user.displayName}
        />
		<TextField
          label="Street Address"
          placeholder="Enter email here"
          helperText="An email will be sent with next information."
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
		  value={userDocData.addressline1}
		  onChange={e => updateFieldData( 'addressline1', e.target.value )}
        />
		<TextField
          label="City"
          placeholder="Enter email here"
          helperText="An email will be sent with next information."
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
		  value={userDocData.addresscity}
		  onChange={e => updateFieldData( 'addresscity', e.target.value )}
        />
		<TextField
          label="State"
          placeholder="Enter email here"
          helperText="An email will be sent with next information."
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
		  value={userDocData.addresstate}
		  onChange={e => updateFieldData( 'addresstate', e.target.value )}
        />
		<TextField
          label="Zipcode"
          placeholder="Enter email here"
          helperText="An email will be sent with next information."
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
		  value={userDocData.addresszip}
		  onChange={e => updateFieldData( 'addresszip', e.target.value )}
        />
		<TextField
          label="Phone Number"
          placeholder="Enter email here"
          helperText="An email will be sent with next information."
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
		  value={userDocData.phoneNum}
		  onChange={e => updateFieldData( 'phoneNum', e.target.value )}
        />
		{ hasUpdated ? 
		<Button variant="outlined" onClick={updateValues}>Update</Button>
		:
		<div></div>
		}
        </form>
      </div>
	  </Container>
    </div>
    </div>
  );
}else{
		return (
			<div>
				<Spinner/>
			</div>
		)
  }
};
export default PersonalInfo;