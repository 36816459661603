import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';
import { BorderTop } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    position:'fixed',
    left:'0',
    right:'0',
    bottom:'0',
    borderTop:'1px solid #C0C0C0',
    zIndex:1
  },
});

export default function LabelBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation value={value} onChange={handleChange} className="bottomNavCont darkBack">
      <BottomNavigationAction component={Link} to='/' label="Search" icon={<SearchIcon />} />
      <BottomNavigationAction component={Link} to='/myaccount' label="Account" icon={<AccountCircleIcon />} />
      <BottomNavigationAction component={Link} to='/contacts' label="Inbox" icon={<MailOutlineIcon />} />
      <BottomNavigationAction component={Link} to='/addnewrental' label="Add"  icon={<AddIcon />} />
      <BottomNavigationAction component={Link} to='/help' label="Help"  icon={<HelpIcon />} />
    </BottomNavigation>
  );
}
