import { Button, Container } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import Divider from '@material-ui/core/Divider';
import {firestore} from '../../../config'


export default function UserDetails(props){
    const history = useHistory()
    const user = useContext(UserContext);
    console.log(props)
    //var reviewsArray = [1,2,3]
    const [reviewsArray, setReviewsArray] = useState([])

    useEffect(()=>{


		const userDetails = firestore.collection('reviews').doc(userUid);
		const doc = userDetails.get().then(doc => {
            console.log(doc.data())
            setReviewsArray(doc.data().reviews)
            console.log(reviewsArray)
        })

    },[])
    if(props.location.query === undefined){
        history.push('/')
        return <></>
    }
    const userNumber = props.location.query.userNum
    const userName = props.location.query.userName
    const userEmail = props.location.query.userEmail
    const userUid = props.location.query.userUid
    const userPhoto = props.location.query.ownerPhoto
    const rentalImage = props.location.query.rentalPhoto
    const rentalName = props.location.query.rentalTit
    const ownerStatResponseT = "123"
    const ownerStatResponseR = "1%"

    const clickEmail = () =>{
        window.location.href = "mailto:"+userEmail+"?subject=I had a question about your rental.&body=Rental Name : " + rentalName;

    }
    const clickPhone = () =>{
        if(userNumber == ""){
            alert("User has no phone attached.")
        }else{
            window.location.href = "tel:"+userNumber;
        }
    }

    return (
        <div style={{marginBottom:'150px'}}>
        <Container maxWidth="sm">
            <div style={{display:'flex',justifyContent:'space-evenly',margin:'10px 0px'}}>
                <img src='/images/My-Account-Icon.png' style={{width:50, height:50}}/>
                <h1>{userName}</h1>
            </div>
            <div style={{display:'flex', justifyContent:'space-evenly'}}>
                <Link to={{ pathname: '/contacts', query: { toUserUid:userUid,toUserPhoto: userPhoto,toUserEmail: userEmail,toUsername: userName, rname:rentalName , rimage: rentalImage} }}>
                    <Button variant='outlined'>Message</Button>
                </Link>
                <Button variant="outlined" onClick={clickPhone}>Call</Button>
                <Button variant="outlined" onClick={clickEmail}>Email</Button>
            </div>
            <br/>
            <div style={{textAlign:'center',fontSize:'large',margin:'10px 0px'}}>Reviews</div>
            <div>
            {reviewsArray.map(function(item,index){
							return (
                                <div>
                                    <div>
                                        <div style={{display:'flex',justifyContent:'space-between'}}>
                                            <div><b>{item.revName}</b></div>
                                            <div style={{float:'right'}}>({item.revNum.toFixed(1)}) Stars</div>
                                        </div>
                                        <div>{item.revText}</div>
                                    </div>
                                    <br/>
                                    <Divider/>
                                </div>
							)
						})}		
            </div>

        </Container>
        </div>
    )
                    
}