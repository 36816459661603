import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import {tileData} from './CategoryCarousel'
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        justifyContent: 'space-evenly'
    },
    paper: {
        height: 50,
        width: 140,
        fontSize: '10px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    innerpaper: {
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    control: {
        padding: theme.spacing(2),
    },
    title: {
        margin: '5%',
        fontWeight: 'bold',
    },
    containClass:{
        justifyContent: 'space-evenly'
    }
}));

export default function LowerCategoryBrowse() {
    const classes = useStyles();
    const history = useHistory();

    const clickImage = (val) => {
        history.push('/category?cat=' + val)
      }
    return (
        <Grid container className={classes.root} spacing={2}>
            <div className={classes.title}>Explore Popular Categories</div>
            <Grid item xs={12}>
                <Grid container className={classes.containClass}>
                    {tileData.map((value) => (
                        <Grid key={'lower'+value.key} item>
                            <Paper className={classes.paper} onClick={()=> clickImage(value.title)}>
                                <div className={classes.innerpaper}>
                                    <img src={value.img} width="40" height="40" style={{margin: '5px'}} />
                                    <div style={{margin:'auto'}}>{value.title}</div>
                                </div>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )

}