import React, {useState , useEffect } from "react";
import Calendar from 'react-calendar';
import Container from '@material-ui/core/Container';
import 'react-calendar/dist/Calendar.css';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    dividerSpace: {
        margin:'10px 0px'
    },
    horizCont:{
        display:'flex',
        justifyContent:'space-around',
        margin:'30px 0px'
    }
}));

const formatDay = (date) =>{
    var dateString = ""
    switch(date.getDay()){
        case 0 :
            dateString = 'Sun'
            break;
        case 1 : 
            dateString = 'Mon'
            break;
        case 2 : 
            dateString = 'Tues'
            break;
        case 3 : 
            dateString = 'Wed'
            break;
        case 4 : 
            dateString = 'Thur'
            break;
        case 5 : 
            dateString = 'Mon'
            break;
        case 6 : 
            dateString = 'Mon'
            break;
    }
    return dateString;
}
const formatMonth = (date) =>{
    var dateString = ""
    switch(date.getMonth()){
        case 0 :
            dateString = 'Jan'
            break;
        case 1 : 
            dateString = 'Feb'
            break;
        case 2 : 
            dateString = 'March'
            break;
        case 3 : 
            dateString = 'April'
            break;
        case 4 : 
            dateString = 'May'
            break;
        case 5 : 
            dateString = 'June'
            break;
        case 6 : 
            dateString = 'July'
            break;
        case 7 : 
            dateString = 'Aug'
            break;
        case 8 : 
            dateString = 'Sep'
            break;
        case 9 : 
            dateString = 'Oct'
            break;
        case 10 : 
            dateString = 'Nov'
            break;
        case 11 : 
            dateString = 'Dec'
            break;
    }
    return dateString;
}
export const formatDate = (date) => {
    return formatDay(date) + ', ' + formatMonth(date) + ' ' + date.getDate() 
}
export var selectedStartDate = null;
export var selectedEndDate = null;
export default function DatePicker(){
    const [selectedDate, onChange] = useState(null);
    const classes = useStyles();
	const history = useHistory()
    function goBack(){
        history.goBack()
      }
    useEffect(() => {
        var selectData = new Array();
        if(selectedStartDate != null){
            selectData.push(selectedStartDate)
            onChange(selectData)
        }
        if(selectedEndDate != null){
            selectData.push(selectedEndDate)
            onChange(selectData)
        }
	}, []);
    const getStart = () => {
        if(selectedDate[0] != null){
            const date = new Date(selectedDate[0])
            selectedStartDate = date
            return formatDate(date)
        }
        return "Start Date"
    }
    const getEnd = () => {
        if(selectedDate[1] != null && selectedDate[1] != undefined){
                const date = new Date(selectedDate[1])
                selectedEndDate = date
                return formatDate(date)
        }else{
            return "End Date"
        }
    }
    return (
      <div>
          <Container maxWidth="sm">
              <h1 style={{textAlign:'center'}}>Select Dates</h1>
                <Calendar
                allowPartialRange={true}
                selectRange={true}
                onChange={onChange}
                value={selectedDate}
                />
                <div className={classes.horizCont}>
                    <div>{selectedDate == null ? 'Start Date' : getStart()}</div>
                    <div>{selectedDate == null ? 'Start Date' : getEnd()}</div>
                </div>
                <div className={classes.horizCont}>
                    <Button variant="outlined" onClick={goBack}>Continue</Button>
                </div>
        </Container>
      </div>
    );
  }